import { decodeEntities } from '../helpers/stringHelpers'

const initialState = {
  countries: {}
}

export default function countries (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'COUNTRIES_GET_SUCCESS': {
      const countriesMap = Object.values(action.countries).reduce((acc, val) => {
        acc[val.countryCode] = decodeEntities(val.countryName)
        return acc
      }, {})

      return Object.assign({}, state, {
        countries: Object.assign({}, state.countries, countriesMap)
      })
    }

    default:
      return state
  }
}
