import APIRequest from '../helpers/apiHelpers'
import { decodeEntities } from '../helpers/stringHelpers'
import get from 'lodash.get'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import User from '../models/user.model'
import authHelpers from '../helpers/authHelpers'
import i18n from '../helpers/i18n'
import { setUser } from '../withTracking'

const config = {
  getBaseModels: () => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'CONFIG_MODELS_GET'
      })
      return APIRequest.get(apiBase, clientId, 'models')
        .then(async (res) => {
          const translations = get(res.body, 'stringsModel.i16Strings')
          const currentLanguageCode = get(res.body, 'userModel.language.code')
          if (!translations || !currentLanguageCode) {
            throw new Error(
              'The API request did not contain all the required information'
            )
          }
          for (const key in translations) {
            translations[key] = decodeEntities(translations[key])
          }
          if (res.body.countries) {
            dispatch({
              type: 'COUNTRIES_GET_SUCCESS',
              countries: res.body.countries
            })
          }

          try {
            i18n.addResourceBundle(
              currentLanguageCode,
              'defaultNamespace',
              translations,
              true,
              true
            )
            i18n.setDefaultNamespace('defaultNamespace')
            await i18n.changeLanguage(currentLanguageCode)
            const user = get(res.body, 'userModel')
            if (user) {
              dispatch({
                type: 'USER_MODEL_GET_SUCCESS',
                user: new User(user).toJSON()
              })
              Sentry.setUser({
                id: user.id,
                uuid: user.uuid,
                email: user.email
              })
              setUser(user.uuid, currentLanguageCode)
            }
            authHelpers.handleAuthenticationHooks(
              !!user.id,
              user.displayName,
              user.adminLoginPermitted,
              user.publicVendorAdmin
            )

            if (window.bidjs?.callback) {
              window.bidjs.callback({
                action: 'BIDJS_MODULES_LOADED'
              })
            }
            Sentry.setTag('page_locale', currentLanguageCode)
            const serverTimestamp = get(
              res.body,
              'serverConfigModel.serverTimestamp'
            )
            return dispatch({
              type: 'CONFIG_MODELS_GET_SUCCESS',
              currentLanguageCode,
              options: {
                isAccountActivationAdminOnly: get(
                  res.body,
                  'webAppModel.options.adminOnlyAccountActivation',
                  true
                ),
                isCardVerificationAvailable: get(
                  res.body,
                  'webAppModel.options.cardVerificationSwitchedOn',
                  false
                ),
                isVATNumberCollectable: get(
                  res.body,
                  'webAppModel.options.requestVatNumber',
                  false
                )
              },
              timeOffset: serverTimestamp
                ? new Date().getTime() - serverTimestamp
                : 0,
              translations,
              uid: get(res.body, 'serverConfigModel.uid')
            })
          } catch (err) {
            toast.error(decodeEntities(err.message), {
              autoClose: false
            })
            return dispatch({
              type: 'CONFIG_MODELS_GET_FAILED',
              err
            })
          }
        })
        .catch((err) => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          return dispatch({
            type: 'CONFIG_MODELS_GET_FAILED',
            err
          })
        })
    }
  }
}

export default config
