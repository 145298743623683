import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from '../../helpers/stringHelpers'
import Modal from '../modal'
import ModalBody from '../modalBody'
import ModalFooter from '../modalFooter'
import Button from '../button'

const Timer = ({ hasTimer, handleClose }) => {
  const [seconds, setSeconds] = React.useState(60)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(sec => sec - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Button
    active={false}
    disabled={hasTimer && seconds >= 0}
    block={true}
    onClick={() => {
      handleClose()
      window.location.reload()
    }}
    color="default"
    className="modal__cta--reload"
    >
    <span>
      REFRESH {hasTimer && seconds >= 0 ? seconds : null}
    </span>
    </Button>
  )
}

const ConfirmModal = ({
  buttonText,
  color,
  className,
  handleClose,
  icon,
  id,
  isOpen,
  message,
  pageNeedsRefreshing,
  hasTimer = false
}) => (
  <Modal size='sm' id={id} className={className} isOpen={isOpen} onClose={handleClose} shouldCloseOnBackDrop={!pageNeedsRefreshing}>
    <ModalBody className='text-center'>
      {icon
        ? (
          <FontAwesomeIcon icon={icon} className={`modal__icon modal__icon--${color}`} />
          )
        : null}
      <p className='modal__message' dangerouslySetInnerHTML={{ __html: message }} />
      {pageNeedsRefreshing
        ? (
          <ModalFooter>
            <Timer hasTimer={hasTimer} handleClose={handleClose}/>
          </ModalFooter>
          )
        : (
          <Button
            className='modal__cta--close'
            size='sm'
            block
            onClick={handleClose}
          >
            {buttonText || translate('JspPublicCodes_JSP_CONFIRM_MODAL_CLOSE_DEFAULT')}
          </Button>
          )}
    </ModalBody>
  </Modal>
)

ConfirmModal.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.oneOf(['success', 'warning', 'info', 'danger']),
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  icon: PropTypes.object,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  pageNeedsRefreshing: PropTypes.bool,
  hasTimer: PropTypes.bool
}

Timer.propTypes = {
  hasTimer: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
}

ConfirmModal.defaultProps = {
  color: 'success',
  message: ''
}

export default memo(ConfirmModal)
