import { decodeEntities } from '../helpers/stringHelpers'
import get from 'lodash.get'

export default class UserAccount {
  constructor (userAccountRaw) {
    this.addressDetails = {
      addressLine1: decodeEntities(get(userAccountRaw, 'contactDetails.addressLine1')) || '',
      addressLine2: decodeEntities(get(userAccountRaw, 'contactDetails.addressLine2')) || '',
      city: decodeEntities(get(userAccountRaw, 'contactDetails.city')) || '',
      countryId: get(userAccountRaw, 'contactDetails.country', null),
      state: decodeEntities(get(userAccountRaw, 'contactDetails.countyOrState')) || '',
      postalCode: get(userAccountRaw, 'contactDetails.postcode') || ''
    }
    this.username = decodeEntities(get(userAccountRaw, 'username')) || ''
    this.email = decodeEntities(get(userAccountRaw, 'email')) || ''
    this.emailRepeat = decodeEntities(get(userAccountRaw, 'repeatEmail')) || ''
    this.telephone = get(userAccountRaw, 'contactDetails.phoneNumber') || ''
    this.mobile = get(userAccountRaw, 'contactDetails.mobileNumber') || ''
    this.fax = get(userAccountRaw, 'contactDetails.faxNumber') || ''
    this.timezoneId = get(userAccountRaw, 'contactDetails.timeZone', null)
    this.companyName = decodeEntities(get(userAccountRaw, 'personalDetails.companyName')) || ''
    this.vatNumber = get(userAccountRaw, 'personalDetails.vatNumber') || ''
    this.jobTitle = decodeEntities(get(userAccountRaw, 'personalDetails.jobTitle')) || ''
    this.forename = decodeEntities(get(userAccountRaw, 'personalDetails.forename')) || ''
    this.surname = decodeEntities(get(userAccountRaw, 'personalDetails.surname')) || ''
    this.password = get(userAccountRaw, 'password') || ''
    this.passwordRepeat = get(userAccountRaw, 'repeatPassword') || ''

    this.isAnalyticsPermitted = get(userAccountRaw, 'userPreferences.dataAnalyticsOk', false)
    this.isEmailPermitted = get(userAccountRaw, 'userPreferences.receiveEmailsOk', false)
    this.isOutbidEmailPermitted = get(userAccountRaw, 'userPreferences.outbidEmailsOk', false)
    this.isTermsAccepted = get(userAccountRaw, 'agreedTsAndCsAndPrivacyPolicy', false)
    this.categories = get(userAccountRaw, 'userPreferences.categories', null) || []

    return this
  }

  static newToServerJSON (userAccountObject) {
    return {
      '@class': '.AccountManagementFormModel',
      username: userAccountObject.username,
      email: userAccountObject.email,
      repeatEmail: userAccountObject.emailRepeat,
      password: userAccountObject.password,
      repeatPassword: userAccountObject.passwordRepeat,
      agreedTsAndCsAndPrivacyPolicy: userAccountObject.isTermsAccepted,
      securityQuestion: null,
      securityAnswer: null,
      contactDetails: {
        '@class': '.ContactDetailsModel',
        addressLine1: get(userAccountObject, 'addressDetails.addressLine1', ''),
        addressLine2: get(userAccountObject, 'addressDetails.addressLine2', '') || '',
        city: get(userAccountObject, 'addressDetails.city', ''),
        countyOrState: get(userAccountObject, 'addressDetails.state', ''),
        postcode: get(userAccountObject, 'addressDetails.postalCode', ''),
        country: get(userAccountObject, 'addressDetails.countryId', null),
        countryCode: null,
        countryName: null,
        timeZone: (userAccountObject.timezoneId).toString(),
        phoneNumber: userAccountObject.telephone,
        mobileNumber: userAccountObject.mobile || '',
        faxNumber: userAccountObject.fax || '',
        validLatLong: false,
        latitude: null,
        longitude: null,
        website: null,
        contactName: null,
        email: null
      },
      phoneVerificationDetails: {
        '@class': '.PhoneVerificationModel',
        phoneNumber: null,
        mobileNumber: null,
        phonePrefix: null,
        code: null,
        infoMessage: null,
        requestId: null,
        success: false,
        locale: '1_302',
        language: null,
        country: null,
        numberVerified: false,
        allowRetry: false,
        alreadyVerified: false,
        verificationStarted: false,
        numberToUse: null,
        verifiedNumber: null
      },
      personalDetails: {
        '@class': '.PersonalDetailsModel',
        forename: userAccountObject.forename,
        surname: userAccountObject.surname,
        companyName: userAccountObject.companyName,
        jobTitle: userAccountObject.jobTitle || '',
        vatNumber: userAccountObject.vatNumber || '',
        language: null
      },
      userPreferences: {
        '@class': '.UserPreferencesModel',
        receiveEmailsOk: userAccountObject.isEmailPermitted,
        outbidEmailsOk: userAccountObject.isOutbidEmailPermitted,
        dataAnalyticsOk: userAccountObject.isAnalyticsPermitted,
        marketingEmailsOk: false,
        signupSource: null,
        categories: userAccountObject.categories
      }
    }
  }

  toJSON () {
    return {
      addressDetails: this.addressDetails,
      categories: this.categories,
      username: this.username,
      email: this.email,
      emailRepeat: this.emailRepeat,
      telephone: this.telephone,
      mobile: this.mobile,
      fax: this.fax,
      timezoneId: this.timezoneId,
      companyName: this.companyName,
      vatNumber: this.vatNumber,
      jobTitle: this.jobTitle,
      forename: this.forename,
      surname: this.surname,
      password: this.password,
      passwordRepeat: this.passwordRepeat,
      isAnalyticsPermitted: this.isAnalyticsPermitted,
      isEmailPermitted: this.isEmailPermitted,
      isOutbidEmailPermitted: this.isOutbidEmailPermitted,
      isTermsAccepted: this.isTermsAccepted
    }
  }
}
