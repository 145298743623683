import { decodeEntities } from '../helpers/stringHelpers'
import { millisToLocaleString } from '../helpers/dateHelpers'
import get from 'lodash.get'

export default class WebcastSaleSummary {
  constructor (webcastSaleSummaryRaw) {
    this.id = webcastSaleSummaryRaw.id
    this.auctionTitle = decodeEntities(webcastSaleSummaryRaw.title)
    this.startDate = get(webcastSaleSummaryRaw, 'startDate.millis')
    this.startDateZone = get(webcastSaleSummaryRaw, 'startDate.chronology.zone.id', null)
    this.startDateFormatted = this.startDate ? millisToLocaleString(this.startDate, this.startDateZone) : null
    this.itemCount = webcastSaleSummaryRaw.itemCount
    this.bidderCount = webcastSaleSummaryRaw.bidderCount
    this.currencyCode = get(webcastSaleSummaryRaw, 'currency.code')
    this.prebid = webcastSaleSummaryRaw.prebidAmount
    this.prebidFormatted = (typeof this.prebid === 'number') ? (this.prebid / 100).toLocaleString() : '0'
    this.soldSellingValue = webcastSaleSummaryRaw.soldSellingAmount
    this.soldSellingValueFormatted = (typeof this.soldSellingValue === 'number') ? (this.soldSellingValue / 100).toLocaleString() : '0'

    return this
  }

  toJSON () {
    return {
      id: this.id,
      auctionTitle: this.auctionTitle,
      startDate: this.startDateFormatted,
      itemCount: this.itemCount,
      bidderCount: this.bidderCount,
      currencyCode: this.currencyCode,
      prebid: this.prebid,
      prebidFormatted: this.prebidFormatted,
      soldSellingValue: this.soldSellingValue,
      soldSellingValueFormatted: this.soldSellingValueFormatted
    }
  }
}
