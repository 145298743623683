import { decodeEntities } from '../helpers/stringHelpers'

const PAYMENT_STATUS = {
  1: {
    id: 1,
    name: 'CreditNote',
    translationKey: 'JspPublicCodes_JSP_BIDJS_INVOICE_CREDIT'
  },
  2: {
    id: 2,
    name: 'PaymentDue',
    translationKey: 'JspPublicCodes_JSP_UNPAID'
  },
  3: {
    id: 3,
    name: 'PaymentComplete',
    translationKey: 'JspPublicCodes_JSP_INVOICE_PAID'
  }
}

export default class Invoice {
  constructor (invoiceRaw) {
    this.identifier = invoiceRaw.reference
    this.id = invoiceRaw.id
    this.versionId = invoiceRaw.invoiceVersionId
    this.billingDuration = invoiceRaw.displayDate
    this.auctionId = invoiceRaw.auctionId
    this.title = decodeEntities(invoiceRaw.auctionName)
    this.paymentStatus = PAYMENT_STATUS[invoiceRaw.credited ? 1 : invoiceRaw.paid ? 3 : 2]
    this.minimumPaymentDue = {
      currency: invoiceRaw.currencyCode,
      value: invoiceRaw.outstandingBalance
    }
    this.minimumPaymentDueFormatted = (typeof this.minimumPaymentDue.value === 'number')
      ? this.minimumPaymentDue.value.toLocaleString(undefined, {
        style: 'currency',
        currency: this.minimumPaymentDue.currency,
        currencyDisplay: 'symbol'
      })
      : '0'
    this.totalPaymentDue = {
      currency: invoiceRaw.currencyCode,
      value: invoiceRaw.totalToPay
    }
    this.totalPaymentDueFormatted = (typeof this.totalPaymentDue.value === 'number')
      ? this.totalPaymentDue.value.toLocaleString(undefined, {
        style: 'currency',
        currency: this.totalPaymentDue.currency,
        currencyDisplay: 'symbol'
      })
      : '0'

    this.referencesOrders = invoiceRaw.itemIds

    return this
  }

  toJSON () {
    return {
      identifier: this.identifier,
      id: this.id,
      auctionId: this.auctionId,
      billingDuration: this.billingDuration,
      title: this.title,
      paymentStatus: this.paymentStatus,
      totalPaymentDue: this.totalPaymentDue,
      totalPaymentDueFormatted: this.totalPaymentDueFormatted,
      minimumPaymentDue: this.minimumPaymentDue,
      minimumPaymentDueFormatted: this.minimumPaymentDueFormatted,
      referencesOrders: this.referencesOrders,
      versionId: this.versionId
    }
  }
}
