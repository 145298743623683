import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ConfigContext from '../../contexts/configContext'
import { Link } from 'react-router-dom'

const transparentPlaceholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

let srcBaseUrl
let containerStyle

const LazyImage = ({
  aspectHeight,
  aspectWidth,
  className,
  dataRef,
  imageCropModeOverride,
  id,
  identifier,
  label,
  onClick,
  src,
  to,
  width,
  key,
  ...props
}) => {
  const { mediaCDN, imageCropMode, imageGravity, imagePadColor } = useContext(ConfigContext)
  if (!srcBaseUrl || imageCropModeOverride) {
    const gravity = ((imageCropModeOverride || imageCropMode) === 'fill') ? imageGravity : 'center'
    const baseUrl = `${mediaCDN}/image/upload/ar_${aspectWidth}:${aspectHeight},b_${imagePadColor},c_${imageCropModeOverride || imageCropMode},g_${gravity},f_auto,`
    srcBaseUrl = `${baseUrl}q_auto:good,`
    containerStyle = {
      paddingBottom: `${(aspectHeight / aspectWidth * 100)}%`
    }
  }
  const image = (
    <img
      src={transparentPlaceholder}
      data-ref={dataRef}
      data-sizes='auto'
      data-srcset={`
        ${srcBaseUrl}w_200/${src} 200w,
        ${srcBaseUrl}w_300/${src} 300w,
        ${srcBaseUrl}w_400/${src} 400w,
        ${srcBaseUrl}w_650/${src} 650w,
        ${srcBaseUrl}w_950/${src} 950w,
        ${srcBaseUrl}w_1200/${src} 1200w,
        ${srcBaseUrl}w_2048/${src} 2048w
      `}
      className={`img-responsive lazy ${className} lazyload`}
      alt={label}
      {...props}
    />
  )

  const imageWithButton = onClick
    ? (
      <button
        className='a11y__interactive--button'
        onClick={(e) => onClick(identifier || e)}
        aria-label={`${label} button`}
      >
        {image}
      </button>
      )
    : image

  return (
    <div
      id={id}
      className='ratio-container'
      key={identifier || id || src || key}
    >
      <div
        className='ratio-container--holder' style={containerStyle}
      />
      {to
        ? (
        <Link to={to} aria-label={`${label} link`}>
          {imageWithButton}
        </Link>
          )
        : imageWithButton}
    </div>
  )
}

LazyImage.propTypes = {
  aspectHeight: PropTypes.number.isRequired,
  aspectWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
  dataRef: PropTypes.string,
  id: PropTypes.string,
  identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  imageCropModeOverride: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  to: PropTypes.string,
  width: PropTypes.string
}

LazyImage.defaultProps = {
  className: '',
  aspectHeight: 3,
  aspectWidth: 4,
  identifier: null,
  onClick: null
}

const isEqual = (prevProps, nextProps) => prevProps.key === nextProps.key &&
  prevProps.onClick === nextProps.onClick &&
  prevProps.className === nextProps.className &&
  prevProps.id === nextProps.id &&
  prevProps.identifier === nextProps.identifier &&
  prevProps.src === nextProps.src &&
  prevProps.to === nextProps.to &&
  prevProps.label === nextProps.label

export default React.memo(LazyImage, isEqual)
