const initialState = {
  orders: {}
}

export default function orders (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'ORDERS_FETCH_SUCCESS':
      return Object.assign({}, state, {
        orders: action.orders
      })

    default:
      return state
  }
}
