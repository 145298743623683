import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TextBlock from '../../components/textBlock'
import { translate } from '../../helpers/stringHelpers'

export const ErrorUnauthorised = (props) => (
  <div>
    <h1>{translate('JspPublicCodes_ERROR_UNAUTHORISED_TITLE')}</h1>
    <TextBlock>{translate('JspPublicCodes_ERROR_UNAUTHORISED_DESCRIPTION')}</TextBlock>
    <Link to={props.returnPath}>&laquo; {translate('JSTextCodes_BACK')}</Link>
  </div>
)

ErrorUnauthorised.propTypes = {
  returnPath: PropTypes.string.isRequired
}

ErrorUnauthorised.defaultProps = {
  returnPath: '/'
}

export default ErrorUnauthorised
