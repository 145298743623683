
const CHUNK_LOAD_ATTEMPTS = 5
const CHUNK_LOAD_INTERVAL_MS = 1000

export const retry = (load, retriesLeft = CHUNK_LOAD_ATTEMPTS, interval = CHUNK_LOAD_INTERVAL_MS) => new Promise(
  (resolve, reject) => {
    load()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(load, interval, retriesLeft - 1).then(resolve, reject)
        }, interval)
      })
  })
