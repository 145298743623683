const initialState = {
  approvedRegistrantCount: '0',
  geoChartStats: [],
  hasSalesErrors: false,
  hasVendorErrors: false,
  isSaleStatsLoading: false,
  isVendorStatsLoading: false,
  referrerChartStats: [],
  registrantCount: '0',
  title: '',
  totalVisits: '0',
  vendorStats: [],
  visitChartStats: []
}

export default function sales (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'SALE_STATS_GET':
      return Object.assign({}, state, {
        isSaleStatsLoading: !action.isSilent
      })

    case 'SALE_STATS_GET_SUCCESS':
      return Object.assign({}, state, {
        approvedRegistrantCount: action.approvedRegistrantCount,
        geoChartStats: action.geoChartStats,
        hasSalesErrors: false,
        isSaleStatsLoading: false,
        referrerChartStats: action.referrerChartStats,
        registrantCount: action.registrantCount,
        totalVisits: action.totalVisits,
        visitChartStats: action.visitChartStats
      })

    case 'SALE_STATS_GET_FAILED':
      return Object.assign({}, state, {
        hasSalesErrors: true,
        isSaleStatsLoading: false
      })

    case 'VENDOR_STATS_GET':
      return Object.assign({}, state, {
        isVendorStatsLoading: !action.isSilent
      })

    case 'VENDOR_STATS_GET_SUCCESS':
      return Object.assign({}, state, {
        auctionType: action.auctionType,
        hasVendorErrors: false,
        isVendorStatsLoading: false,
        title: action.title,
        vendorStats: action.vendorStats
      })

    case 'VENDOR_STATS_GET_FAILED':
      return Object.assign({}, state, {
        hasVendorErrors: true,
        isVendorStatsLoading: false
      })

    default:
      return state
  }
}
