import React from 'react'
import PropTypes from 'prop-types'
import { ListGroupItem } from 'react-bootstrap'

const ListItem3 = (props) => {
  return (
    <ListGroupItem
      active={props.isActive}
      className={props.className}
      color={props.color}
      disabled={props.isDisabled}
      href={props.href}
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </ListGroupItem>
  )
}

ListItem3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
  href: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
}

ListItem3.defaultProps = {
  isActive: false,
  isDisabled: false
}

export default ListItem3
