import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'

const Badge3 = ({ className, color, children, ...remainingProps }) => {
  return (
    <Label className={className} bsStyle={color === 'secondary' ? 'default' : color} {...remainingProps}>
      {children}
    </Label>
  )
}

Badge3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['secondary', 'primary', 'success', 'warning', 'danger', 'info']).isRequired
}

Badge3.defaultProps = {
  color: 'secondary'
}

export default Badge3
