import { decodeEntities } from '../helpers/stringHelpers'
import get from 'lodash.get'

export default class Auction {
  constructor (auctionRaw) {
    this.endDate = auctionRaw.auctionEndTime
    this.id = auctionRaw.auctionId
    this.isPaused = auctionRaw.inPlayPaused || auctionRaw.auctionPaused
    this.saleMessage = get(auctionRaw, 'messageModel.messageText', null)
    this.isSaleMessageForWinnerOnly = (get(auctionRaw, 'messageModel.messageTarget', null) !== 'ALL_BIDDERS')
    this.onceTwiceStatus = auctionRaw.onceTwiceStatus
    this.title = decodeEntities(auctionRaw.auctionTitle)
    this.uuid = auctionRaw.auctionUuid

    return this
  }

  toJSON () {
    return {
      id: this.id,
      isPaused: this.isPaused,
      title: this.title,
      endDate: this.endDate,
      onceTwiceStatus: this.onceTwiceStatus,
      saleMessage: this.saleMessage,
      isSaleMessageForWinnerOnly: this.isSaleMessageForWinnerOnly,
      uuid: this.uuid
    }
  }
}
