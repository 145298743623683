const initialState = {}

export default function mediaFeed (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'MEDIA_FEED_GET_SUCCESS':
      return (state.timestamp && action.timestamp < state.timestamp)
        ? state
        : Object.assign({}, state, action.feed, {
          timestamp: action.timestamp
        })

    default:
      return state
  }
}
