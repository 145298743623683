import { decodeEntities } from '../helpers/stringHelpers'

const initialState = {
  auctionCategories: {},
  categoryChildren: {},
  categoryParents: {},
  categoryTrees: {}
}

export default function categories (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'CATEGORIES_GET_SUCCESS': {
      const categoriesByParentUuid = Object.values(action.categories.parents).reduce((acc, val) => {
        acc.push(val.uuid)
        return acc
      }, [])

      const categoryChildren = {}
      const categoryParents = {}
      const categoryTrees = {}

      for (const key in action.categories.children) {
        if (Object.prototype.hasOwnProperty.call(action.categories.children, key)) {
          categoryChildren[key] = Object.assign({}, action.categories.children[key], {
            name: decodeEntities(action.categories.children[key].name)
          })
        }
      }

      for (const key in action.categories.parents) {
        if (Object.prototype.hasOwnProperty.call(action.categories.parents, key)) {
          categoryParents[key] = Object.assign({}, action.categories.parents[key], {
            name: decodeEntities(action.categories.parents[key].name)
          })
        }
      }

      for (const key in action.categories.trees) {
        if (Object.prototype.hasOwnProperty.call(action.categories.trees, key)) {
          categoryTrees[key] = Object.assign({}, action.categories.trees[key], {
            name: decodeEntities(action.categories.trees[key].name)
          })
        }
      }

      return Object.assign({}, state, {
        auctionCategories: Object.assign({}, state.auctionCategories, {
          [action.auctionUuid]: categoriesByParentUuid
        }),
        categoryChildren: Object.assign({}, state.categoryChildren, categoryChildren),
        categoryParents: Object.assign({}, state.categoryParents, categoryParents),
        categoryTrees: Object.assign({}, state.categoryTrees, categoryTrees)
      })
    }

    default:
      return state
  }
}
