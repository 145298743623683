export const FILTER_KEYS = {
  LIVE: 'liveTimed',
  CLOSED: 'closedTimed',
  LIVE_MARKETPLACE: 'liveMarketplace',
  CLOSED_MARKETPLACE: 'closedMarketplace',
  WEBCAST: 'webcast'
}

const initialState = {
  activeFilterKey: null,
  closedMarketplace: [],
  closedMarketplaceFiltered: [],
  closedTimed: [],
  closedTimedFiltered: [],
  columns: [
    {
      key: 'itemRef',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_ITEM_REF',
      visibleByFilters: [FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE]
    },
    {
      key: 'auctionTitle',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_AUCTION_TITLE',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.CLOSED, FILTER_KEYS.WEBCAST]
    },
    {
      key: 'description',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_LOT_TITLE',
      visibleByFilters: [FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE]
    },
    {
      key: 'closeDate',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_CLOSE_DATE',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.CLOSED, FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE]
    },
    {
      key: 'itemCount',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_LOT_NUM',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.CLOSED]
    },
    {
      key: 'startDate',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_START_DATE',
      visibleByFilters: [FILTER_KEYS.WEBCAST]
    },
    {
      key: 'itemCount',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_NUM_WEBCAST_ITEMS',
      visibleByFilters: [FILTER_KEYS.WEBCAST]
    },
    {
      key: 'bidderCount',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_APPROVED_BIDDERS',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.WEBCAST]
    },
    {
      key: 'offerCount',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_PENDING_OFFERS',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.LIVE_MARKETPLACE]
    },
    {
      key: 'currencyCode',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_CURRENCY',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.CLOSED, FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE, FILTER_KEYS.WEBCAST]
    },
    {
      key: 'prebidFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_PREBIDS',
      visibleByFilters: [FILTER_KEYS.WEBCAST]
    },
    {
      key: 'reserveFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_RESERVE',
      visibleByFilters: [FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE]
    },
    {
      key: 'highestBidFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_CURRENT_HIGHEST_BID',
      visibleByFilters: [FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE]
    },
    {
      key: 'status',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_STATUS',
      visibleByFilters: [FILTER_KEYS.LIVE_MARKETPLACE, FILTER_KEYS.CLOSED_MARKETPLACE],
      type: 'translation'
    },
    {
      key: 'soldSellingValueFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_SOLD',
      visibleByFilters: [FILTER_KEYS.CLOSED, FILTER_KEYS.WEBCAST]
    },
    {
      key: 'soldSellingValueFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_SOLD_AND_SELLING',
      visibleByFilters: [FILTER_KEYS.LIVE]
    },
    {
      key: 'notSellingValueFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_NOT_SELLING',
      visibleByFilters: [FILTER_KEYS.LIVE]
    },
    {
      key: 'unsoldValueFormatted',
      contentKey: 'JspPublicCodes_JSP_MY_SALES_UNSOLD',
      visibleByFilters: [FILTER_KEYS.LIVE, FILTER_KEYS.CLOSED]
    }
  ],
  filters: [
    {
      key: FILTER_KEYS.LIVE,
      contentKey: 'JspPublicCodes_JSP_MY_SALES_LIVE_AUCTIONS',
      toUrl: '/mySales/timed/live'
    }, {
      key: FILTER_KEYS.CLOSED,
      contentKey: 'JspPublicCodes_JSP_MY_SALES_CLOSED_AUCTIONS',
      toUrl: '/mySales/timed/closed'
    }, {
      key: FILTER_KEYS.LIVE_MARKETPLACE,
      contentKey: 'JspPublicCodes_JSP_MY_SALES_LIVE_MARKETPLACE',
      toUrl: '/mySales/marketplace/live'
    }, {
      key: FILTER_KEYS.CLOSED_MARKETPLACE,
      contentKey: 'JspPublicCodes_JSP_MY_SALES_CLOSED_MARKETPLACE',
      toUrl: '/mySales/marketplace/closed'
    }, {
      key: FILTER_KEYS.WEBCAST,
      contentKey: 'JspPublicCodes_JSP_MY_SALES_WEBCAST',
      toUrl: '/mySales/webcast'
    }
  ],
  hasErrors: false,
  isLoading: false,
  liveMarketplace: [],
  liveMarketplaceFiltered: [],
  liveTimed: [],
  liveTimedFiltered: [],
  searchTerm: '',
  userId: null,
  webcast: [],
  webcastFiltered: []
}

const filterMarketplaceByTerm = (term, item) => (
  !term || (item.itemRef === parseInt(term, 10) || item.description.toLowerCase().includes(term))
)

const filterAuctionByTerm = (term, item) => (
  !term || item.auctionTitle.toLowerCase().includes(term)
)

export default function saleSummaries (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'SALE_SUMMARIES_GET':
      return Object.assign({}, state, {
        activeFilterKey: action.filter,
        hasErrors: false,
        isLoading: true,
        userId: action.userId
      })

    case 'SALE_SUMMARIES_GET_SUCCESS': {
      const filterAuction = state.searchTerm && filterAuctionByTerm.bind(null, state.searchTerm.toLowerCase())
      const filterMarketplace = state.searchTerm && filterMarketplaceByTerm.bind(null, state.searchTerm.toLowerCase())
      return Object.assign({}, state, {
        hasErrors: false,
        closedMarketplace: action.closedMarketplace || [],
        closedMarketplaceFiltered: (action.closedMarketplace)
          ? (state.searchTerm)
              ? action.closedMarketplace.filter(filterMarketplace)
              : action.closedMarketplace
          : [],
        closedTimed: action.closedTimed || [],
        closedTimedFiltered: (action.closedTimed)
          ? (state.searchTerm)
              ? action.closedTimed.filter(filterAuction)
              : action.closedTimed
          : [],
        liveMarketplace: action.liveMarketplace || [],
        liveMarketplaceFiltered: (action.liveMarketplace)
          ? (state.searchTerm)
              ? action.liveMarketplace.filter(filterMarketplace)
              : action.liveMarketplace
          : [],
        liveTimed: action.liveTimed || [],
        liveTimedFiltered: (action.liveTimed)
          ? (state.searchTerm)
              ? action.liveTimed.filter(filterAuction)
              : action.liveTimed
          : [],
        webcast: action.webcast || [],
        webcastFiltered: (action.webcast)
          ? (state.searchTerm)
              ? action.webcast.filter(filterAuction)
              : action.webcast
          : [],
        isLoading: false
      })
    }

    case 'SALE_SUMMARIES_GET_FAILED':
      return Object.assign({}, state, {
        hasErrors: (action.error.message) ? action.error.message : 'There was a problem fetching the Sale Summaries',
        closedMarketplace: [],
        closedMarketplaceFiltered: [],
        closedTimed: [],
        closedTimedFiltered: [],
        liveMarketplace: [],
        liveMarketplaceFiltered: [],
        liveTimed: [],
        liveTimedFiltered: [],
        webcast: [],
        webcastFiltered: [],
        isLoading: false
      })

    case 'SALE_SUMMARIES_SEARCH': {
      const term = (action.term?.length) ? action.term.toLowerCase() : null
      const filterExistingAuction = filterAuctionByTerm.bind(this, term)
      const filterExistingMarketplace = filterMarketplaceByTerm.bind(this, term)
      return Object.assign({}, state, {
        searchTerm: action.term,
        closedMarketplaceFiltered: state.closedMarketplace.filter(filterExistingMarketplace),
        closedTimedFiltered: state.closedTimed.filter(filterExistingAuction),
        liveMarketplaceFiltered: state.liveMarketplace.filter(filterExistingMarketplace),
        liveTimedFiltered: state.liveTimed.filter(filterExistingAuction),
        webcastFiltered: state.webcast.filter(filterExistingAuction)
      })
    }

    default:
      return state
  }
}
