
/*
  To enhance error reporting quality, we filter out errors as follows:

  * Exclude all errors originating exclusively from node_modules, deeming them irrelevant to our core application logic.
  * Only consider errors stemming from our bidjs module valid, ensuring focus on our primary codebase.
  * Exclude errors related to WordPress or jQuery to maintain focus on application-specific issues.
*/

export function isValidError (framesArray) {
  const urlRegex = /^https:\/\/static\.bidjs\.com\/\d*/
  const filenameRegex = /wp-content|wp-includes|jquery/
  const nodeModulesRegex = /node_modules/

  // Check if all frames are from node_modules
  if (framesArray.every((frame) => nodeModulesRegex.test(frame.filename))) return false

  // Check if any frame URL matches the specified URL pattern
  if (!framesArray.some((frame) => urlRegex.test(frame.abs_path || frame.filename))) return false

  // Check if any frame filename matches the specified filename pattern
  return !framesArray.some((frame) => filenameRegex.test(frame.filename))
}
