import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

const Col3 = (props) => (
  <Col
    className={props.className}
    lg={props.lg}
    lgOffset={props.lgOffset}
    md={props.md}
    mdOffset={props.mdOffset}
    sm={props.sm}
    smOffset={props.smOffset}
    xs={props.xs}
    xsOffset={props.xsOffset}
  >
    {props.children}
  </Col>
)

Col3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  lg: PropTypes.number,
  lgOffset: PropTypes.number,
  md: PropTypes.number,
  mdOffset: PropTypes.number,
  sm: PropTypes.number,
  smOffset: PropTypes.number,
  xs: PropTypes.number,
  xsOffset: PropTypes.number
}

Col3.defaultProps = {
  className: ''
}

export default Col3
