import React, { memo, useEffect, useState, useContext } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

import { formatCurrency } from '../../helpers/currencyHelpers'
import { translate } from '../../helpers/stringHelpers'
import ConfigContext from '../../contexts/configContext'

import Modal from '../modal'
import ModalBody from '../modalBody'
import ModalFooter from '../modalFooter'
import Button from '../button'
import FullChoice from '../fullChoice'
import { listingSales } from '../../actions'
import webcastItemGroupingType from '../../constants/webcastItemGroupingType'

const InteractiveModal = ({
  type,
  className,
  id,
  isOpen,
  listingUuid,
  listings,
  sales,
  bids,
  saleStatuses,
  handleClose
}) => {
  const listing = listings[listingUuid]
  const sale = sales[listingUuid]
  const saleStatus = saleStatuses[listingUuid]
  const highestBid = bids[saleStatus?.highestBidUuid]
  const [selectedItems, setSelectedItems] = useState([])
  const [price, setPrice] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const isDisabled =
    selectedItems.length < listing?.minimumChoice || isSubmitting
  const { locale } = useContext(ConfigContext)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    setIsSubmitting(true)
    const uuid = uuidv4()
    dispatch(
      listingSales.submitChoice(listing.auctionUuid, selectedItems, uuid)
    ).then((res) => {
      if (res.payload.worked) {
        setSelectedItems([])
        handleClose()
      }
      if (!res.payload.worked) {
        setHasFailed(true)
      }
    })
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (!sale || sale?.length === 0) {
      return
    }
    const numberOfUnits =
      sale?.biddingTypeId === webcastItemGroupingType.PER_ITEM
        ? selectedItems.reduce((acc, itemUuid) => !itemUuid ? acc : acc + sales[itemUuid]?.numberOfUnits, 0)
        : selectedItems.length

    const pricePerSelectedItems =
      sale?.biddingTypeId === webcastItemGroupingType.PER_ITEM
        ? highestBid?.amount * numberOfUnits * selectedItems.length
        : highestBid?.amount * selectedItems.length

    const formattedPricePerSelectedItems = formatCurrency(
      locale,
      sale.currencyCode,
      pricePerSelectedItems
    )
    const formattedTotalPrice = formatCurrency(
      locale,
      sale.currencyCode,
      highestBid?.amount
    )
    setPrice(`${numberOfUnits} x ${formattedTotalPrice} = ${formattedPricePerSelectedItems}`)
  }, [selectedItems, sale, locale, listing, highestBid?.amount, sales])

  if (!listing || !sale || !listing?.groupedItems) {
    return null
  }
  const handleButtonText = () => {
    if (selectedItems.length === 0) {
      return `Minimum ${listing?.minimumChoice}`
    }
    return `${translate('JspPublicCodes_JSP_MY_SETTINGS_CARD_VERIFICATION_BUTTON_SUBMIT')}`
  }
  return (
    <Modal size='lg' id={id} className={className} isOpen={isOpen}>
      <ModalBody className='text-start'>
        <p className='modal__header'>
          {translate('WebcastCodes_WC_BUTTON_SELECT_CHOICE')}
        </p>
        {type === 'FULL' && (
          <FullChoice
            listing={listing}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )}
        {/* {type === 'NUMERIC_ORDER' && (
          <NumericChoice
            listing={listing}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )} */}
      </ModalBody>
      <ModalFooter>
        {hasFailed && (
          <Button
            onClick={handleClose}
            id='close-button'
            type='button'
          >
            Close
          </Button>
        )}
        <OverlayTrigger
          placement='top'
          overlay={
            isDisabled
              ? (
              <Tooltip id='tooltip-disabled'>
                Choose at least {listing?.minimumChoice} item
              </Tooltip>
                )
              : (
              <></>
                )
          }
        >
          <Button
            className={!isDisabled ? 'btn btn-success' : 'btn btn-disabled'}
            disabled={isDisabled}
            onClick={handleSubmit}
            type='submit'
          >
            {handleButtonText()}
            <br />
            {price}
          </Button>
        </OverlayTrigger>
      </ModalFooter>
    </Modal>
  )
}

InteractiveModal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  listingUuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  listings: PropTypes.object.isRequired,
  bids: PropTypes.object.isRequired,
  saleStatuses: PropTypes.object.isRequired,
  sales: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
}

InteractiveModal.defaultProps = {
  message: ''
}

export function mapStateToProps (state) {
  return {
    listings: state.lots.listings,
    sales: state.listingSales.sales,
    bids: state.bids.bids,
    saleStatuses: state.saleStatuses.saleStatuses
  }
}

export default connect(mapStateToProps)(memo(InteractiveModal))
