const notifications = {
  addAuctionNotification: (
    auctionUuid,
    listingUuid,
    notificationType,
    message,
    icon
  ) => {
    return (dispatch) => {
      return dispatch({
        type: 'AUCTION_NOTIFICATION_ADDED',
        auctionUuid,
        listingUuid,
        message,
        notificationType,
        icon
      })
    }
  },
  clearAuctionNotification: (auctionUuid, key) => {
    return (dispatch) => {
      return dispatch({
        type: 'AUCTION_NOTIFICATION_REMOVED',
        auctionUuid,
        key
      })
    }
  },
  addGlobalNotification: (notificationType, message, icon, pageNeedsRefreshing) => {
    return (dispatch) => {
      return dispatch({
        type: 'GLOBAL_NOTIFICATION_ADDED',
        pageNeedsRefreshing,
        message,
        notificationType,
        icon
      })
    }
  },
  clearGlobalNotification: (key) => {
    return (dispatch) => {
      return dispatch({
        type: 'GLOBAL_NOTIFICATION_REMOVED',
        key
      })
    }
  },
  addInteractiveNotification: (listing, notificationType) => {
    return (dispatch) => {
      return dispatch({
        type: 'INTERACTIVE_NOTIFICATION_ADDED',
        notificationType,
        listing
      })
    }
  },

  clearInteractiveNotification: () => {
    return (dispatch) => {
      return dispatch({
        type: 'INTERACTIVE_NOTIFICATION_REMOVED'
      })
    }
  }
}

export default notifications
