import { toast } from 'react-toastify'
import get from 'lodash.get'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

import Auction from '../models/auction.model'
import imageModel from '../models/image.model'

import APIRequest from '../helpers/apiHelpers'
import { decodeEntities, translate } from '../helpers/stringHelpers'
import { formatCurrency } from '../helpers/currencyHelpers'
import webcastStatuses from '../constants/webcastStatuses'
import listingChoices from '../constants/listingChoices'

const decodeLegacyAuction = (auction) =>
  Object.assign({}, auction, {
    typeMessage: decodeEntities(auction.typeMessage),
    endsOrStarts: decodeEntities(auction.endsOrStarts),
    auctionStartTimeString: decodeEntities(auction.auctionStartTimeString),
    title: decodeEntities(auction.title),
    description: decodeEntities(auction.description),
    location: decodeEntities(auction.location)
  })

const parseBid = (bid, isWebcast) =>
  isWebcast
    ? Object.assign({}, bid, {
      amount: bid.amount / 100
    })
    : bid

const parseMaxBid = (maxBid, isWebcast) =>
  isWebcast
    ? Object.assign({}, maxBid, {
      value: maxBid.value / 100
    })
    : maxBid

const parseSale = (sale, isWebcast) =>
  isWebcast
    ? Object.assign({}, sale, {
      incrementValue: sale.incrementValue / 100,
      nextBidValue: sale.nextBidValue / 100
    })
    : sale

const getVisibleNotifications = (notifications) =>
  notifications?.filter((notification) => notification.visible === true)

const getPurchaseSuccessMessage = (purchaseTypeId, isMarketplace) => {
  switch (purchaseTypeId) {
    case 2:
      return isMarketplace
        ? 'JspPublicCodes_JSP_MARKETPLACE_OFFER_APPROVED'
        : 'JspPublicCodes_JSP_LISTING_OFFER_APPROVED'
    case 3:
      return 'JspPublicCodes_JSP_LISTING_BUY_SUCCESSFUL'
    default:
      return ''
  }
}

const auctions = {
  historicGet: () => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'AUCTIONS_HISTORIC_GET'
      })
      return APIRequest.get(apiBase, clientId, 'auctionsArchived')
        .then((res) => {
          const fetchedAuctions = res.body.basicAuctionBidJSModelList.map(
            (auctionModel) => new Auction(auctionModel).toJSON()
          )
          return dispatch({
            type: 'AUCTIONS_HISTORIC_GET_SUCCESS',
            auctions: fetchedAuctions,
            fetchedAt: Date.now()
          })
        })
        .catch((err) => {
          console.error(err)
          dispatch({
            type: 'AUCTIONS_HISTORIC_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  historicSearch: (term) => async (dispatch) =>
    dispatch({
      type: 'AUCTIONS_HISTORIC_SEARCH',
      term
    }),
  listDeprecated: (uid) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'AUCTIONS_DEPRECATED_GET'
      })
      return APIRequest.get(apiBase, clientId, 'home', `uid=${uid}`)
        .then((res) => {
          const upcoming = get(
            res.body,
            'models.HomePageModel.upcomingModel',
            {}
          )
          return dispatch({
            type: 'AUCTIONS_DEPRECATED_GET_SUCCESS',
            auctions: upcoming.upcomingAuctions.map(decodeLegacyAuction),
            countries: upcoming.countries,
            featuredAuctions:
              upcoming.upcomingFeaturedAuctions.map(decodeLegacyAuction),
            marketplaceCategories: upcoming.upcomingMarketplace
              ? upcoming.upcomingMarketplace.map((marketplaceCategory) =>
                Object.assign({}, marketplaceCategory, {
                  parentCategoryName: decodeEntities(
                    marketplaceCategory.parentCategoryName
                  )
                })
              )
              : [],
            registeredAuctions: get(
              res.body,
              'models.HomePageModel.upcomingModel.registeredFor',
              {}
            )
          })
        })
        .catch((err) => {
          console.error(err)
          dispatch({
            type: 'AUCTIONS_DEPRECATED_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  onWebsocketEvent: (event, locale, userUuid = null) => {
    return (dispatch, getState) => {
      dispatch({
        type: 'WEBSOCKET_EVENT',
        event
      })

      const timestamp = event.timestamp
      const auctionUuid = get(event, 'data.auctionUuid')
      const auction =
        auctionUuid &&
        (get(getState(), `auctions.auctions.${event.data.auctionUuid}`) ||
          get(getState(), 'auctions.auctions.marketplace'))
      const isWebcast = auction?.webcast
      const marketplaceFriendlyAuctionUuid = auction?.uuid
      const isMarketplace = marketplaceFriendlyAuctionUuid === 'marketplace'

      try {
        switch (event.type) {
          case 'SUBSCRIBE_TO_AUCTION':
            dispatch({
              type: 'SUBSCRIBE_TO_AUCTION'
            })
            break
          case 'AUCTION_SUBSCRIBED':
            dispatch({
              type: 'AUCTION_SUBSCRIBED_SUCCESS'
            })
            break
          case 'AUCTION_UPDATED':
          case 'WEBCAST_STARTED':
            dispatch({
              type: 'AUCTION_GET_SUCCESS',
              auction: event.data.auction
            })
            break
          case 'BID_PLACED': {
            const isHighestBidMatchingTheCurrentBid =
              event.data.saleStatus.highestBidUuid === event.data.bid.uuid

            if (userUuid) {
              const listingUuid = event.data.sale.listingUuid

              const listing = getState().lots.listings[listingUuid]
              const previousSaleStatus =
                getState().saleStatuses.saleStatuses[listingUuid]
              const previousBid =
                previousSaleStatus &&
                getState().bids.bids[previousSaleStatus.highestBidUuid]
              const maxBid =
                getState().maxBids.maxBidsByListingUuid[listingUuid]

              const isUserPreviousHighBidder =
                previousBid?.userUuid === userUuid
              const isUserTheBidder = event.data.bid.userUuid === userUuid
              const isEventNewer = timestamp >= previousBid?.timestamp

              const hasUserGotHigherMaxBid =
                maxBid?.userUuid === userUuid &&
                maxBid.value >= event.data.bid.amount

              if (
                previousBid &&
                isEventNewer &&
                ((isHighestBidMatchingTheCurrentBid &&
                  isUserPreviousHighBidder &&
                  !isUserTheBidder) ||
                  (!isHighestBidMatchingTheCurrentBid && isUserTheBidder)) &&
                !hasUserGotHigherMaxBid
              ) {
                toast.warning(
                  translate(
                    isMarketplace
                      ? 'JspPublicCodes_JSP_MARKETPLACE_BID_OUTBID'
                      : 'JspPublicCodes_JSP_LISTING_BID_OUTBID'
                  ).replace(
                    '{0}',
                    isMarketplace ? listing.id : listing.lotNumber
                  )
                )
              }
            }
            // bid
            dispatch({
              type: 'BID_GET_SUCCESS',
              bid: parseBid(event.data.bid, isWebcast),
              timestamp
            })

            // sale
            dispatch({
              type: 'LISTING_SALE_GET_SUCCESS',
              sale: parseSale(event.data.sale, isWebcast),
              timestamp
            })

            // saleStatus
            if (isHighestBidMatchingTheCurrentBid) {
              dispatch({
                type: 'SALE_STATUS_GET_SUCCESS',
                saleStatus: event.data.saleStatus,
                timestamp
              })
            }

            // warning
            dispatch({
              type: 'WEBCAST_WARNING_RESET'
            })
            break
          }
          case 'BID_CANCELLED':
          case 'BID_REINSTATED':
            // bid
            dispatch({
              type: 'BID_GET_SUCCESS',
              bid: parseBid(event.data.bid, isWebcast),
              timestamp
            })

            // sale
            dispatch({
              type: 'LISTING_SALE_GET_SUCCESS',
              sale: parseSale(event.data.sale, isWebcast),
              timestamp
            })

            // saleStatus
            dispatch({
              type: 'SALE_STATUS_GET_SUCCESS',
              saleStatus: event.data.saleStatus,
              timestamp
            })

            // warning
            dispatch({
              type: 'WEBCAST_WARNING_RESET'
            })
            break

          case 'REGISTRANT_UPDATED':
            // registrants
            dispatch({
              type: 'REGISTRANT_GET_SUCCESS',
              auctionUuid: event.data.auctionUuid,
              registrant: event.data.registrant,
              timestamp
            })
            break

          case 'SALE_COMPLETED':
          case 'SALE_STARTED':
            if (userUuid) {
              for (const listingUuid in event.data.saleStatuses) {
                const saleStatus = event.data.saleStatuses[listingUuid]

                const sold = saleStatus.sold
                const webcastStateId = saleStatus.webcastStateId
                const highestBidUuid = saleStatus.highestBidUuid ?? null
                const highestOfferUuid = saleStatus.highestOfferUuid ?? null

                const sale = event.data.sales[listingUuid]

                const listing = getState().lots.listings[listingUuid]

                const offer = getState().offers.offers[highestOfferUuid]
                const bid = getState().bids?.bids[highestBidUuid]
                const notifications = getState().notifications

                const isUserOfferer = offer?.userUuid === userUuid
                const isUserBidder = bid?.userUuid === userUuid
                if (sold && sale) {
                  if (isUserOfferer && highestOfferUuid) {
                    dispatch({
                      type: 'AUCTION_NOTIFICATION_ADDED',
                      auctionUuid: marketplaceFriendlyAuctionUuid,
                      listingUuid,
                      message: translate(
                        getPurchaseSuccessMessage(
                          sale.purchaseTypeId,
                          isMarketplace
                        )
                      )
                        .replace(
                          '{0}',
                          formatCurrency(
                            locale,
                            sale.currencyCode,
                            offer.amount
                          )
                        )
                        .replace(
                          '{1}',
                          isMarketplace ? listing.id : listing.lotNumber
                        ),
                      notificationType: 'success'
                    })
                  }
                  if (
                    isUserBidder &&
                    highestBidUuid &&
                    webcastStateId === webcastStatuses.INPLAY_ENDED_WITH_CHOICES
                  ) {
                    dispatch({
                      type: 'INTERACTIVE_NOTIFICATION_ADDED',
                      listingUuid,
                      notificationType: listingChoices[listing.choiceId - 1]
                    })
                  }
                }
                if (!sold && isUserBidder && highestBidUuid) {
                  const storedInteractiveNotifications = JSON.parse(
                    localStorage.getItem('interactiveNotifications')
                  )

                  if (storedInteractiveNotifications) {
                    notifications.interactive.push(
                      ...storedInteractiveNotifications
                    )
                  }
                  const hasInteractiveNotification = getVisibleNotifications(
                    notifications.interactive
                  ).some(
                    (notification) => notification.listingUuid === listingUuid
                  )
                  if (hasInteractiveNotification) {
                    dispatch({
                      type: 'INTERACTIVE_NOTIFICATION_REMOVED'
                    })
                    if (
                      getVisibleNotifications(notifications.global).length === 0
                    ) {
                      dispatch({
                        type: 'GLOBAL_NOTIFICATION_ADDED',
                        notificationType: 'info',
                        icon: faCircleExclamation,
                        message: translate(
                          'JspPublicCodes_JSP_WEBCAST_CHOICE_UNDONE'
                        )
                      })
                    }
                  }
                }
              }
            }
            // saleStatus
            dispatch({
              type: 'SALE_STATUSES_GET_SUCCESS',
              saleStatuses: event.data.saleStatuses,
              timestamp
            })
            if (event.data.inPlay) {
              // webcastInPlay
              dispatch({
                type: 'WEBCAST_INPLAY_GET_SUCCESS',
                inPlay: event.data.inPlay,
                timestamp
              })
            }
            if (event.data.sales) {
              if (isWebcast) {
                for (const sale in event.data.sales) {
                  if (
                    Object.prototype.hasOwnProperty.call(event.data.sales, sale)
                  ) {
                    event.data.sales[sale] = parseSale(
                      event.data.sales[sale],
                      isWebcast
                    )
                  }
                }
              }
              // webcastInPlay
              dispatch({
                type: 'LISTING_SALES_GET_SUCCESS',
                sales: event.data.sales,
                timestamp
              })
            }
            break

          case 'SALE_UNITS_BIDDING_TYPE_SET':
            // sale
            dispatch({
              type: 'LISTING_SALE_GET_SUCCESS',
              sale: parseSale(event.data.sale, isWebcast),
              timestamp
            })
            // saleStatus
            dispatch({
              type: 'SALE_STATUS_GET_SUCCESS',
              saleStatus: event.data.saleStatus,
              timestamp
            })
            break

          case 'SALE_WITHDRAWN': {
            // auction
            dispatch({
              type: 'AUCTION_LISTINGS_REMOVE_SUCCESS',
              auctionUuid: marketplaceFriendlyAuctionUuid,
              listingUuids: Object.keys(event.data.sales),
              listings: getState().lots.listings,
              timestamp
            })
            // saleStatus
            dispatch({
              type: 'SALE_STATUSES_GET_SUCCESS',
              saleStatuses: event.data.saleStatuses,
              timestamp
            })

            const interactiveNotifications =
              getState().notifications.interactive

            const storedInteractiveNotifications = JSON.parse(
              localStorage.getItem('interactiveNotifications')
            )

            const visibleInteractiveNotifications = getVisibleNotifications(
              interactiveNotifications
            )
            const visibleStoredInteractiveNotifications = getVisibleNotifications(
              storedInteractiveNotifications
            )
            if (
              visibleInteractiveNotifications.length ||
              visibleStoredInteractiveNotifications?.length
            ) {
              dispatch({
                type: 'INTERACTIVE_NOTIFICATION_REMOVED'
              })
              dispatch({
                type: 'GLOBAL_NOTIFICATION_ADDED',
                notificationType: 'info',
                icon: faCircleExclamation,
                message: translate(
                  'JspPublicCodes_JSP_WEBCAST_CHOICE_MADE_BY_AUCTIONEER'
                )
              })
            }
            break
          }

          case 'SALE_ADDED': {
            if (isWebcast) {
              for (const sale in event.data.sales) {
                if (
                  Object.prototype.hasOwnProperty.call(event.data.sales, sale)
                ) {
                  event.data.sales[sale] = parseSale(
                    event.data.sales[sale],
                    isWebcast
                  )
                }
              }
            }
            // sale
            dispatch({
              type: 'LISTING_SALES_GET_SUCCESS',
              sales: event.data.sales,
              timestamp
            })
            // saleStatus
            dispatch({
              type: 'SALE_STATUSES_GET_SUCCESS',
              saleStatuses: event.data.saleStatuses,
              timestamp
            })
            // lot
            dispatch({
              type: 'LISTINGS_GET_SUCCESS',
              listings: event.data.listings,
              isMarketplace,
              timestamp
            })
            // auction
            dispatch({
              type: 'AUCTION_LISTINGS_ADD_SUCCESS',
              auctionUuid: marketplaceFriendlyAuctionUuid,
              listingUuids: Object.keys(event.data.sales),
              listings: getState().lots.listings,
              timestamp
            })
            // image
            const transformedImages = {}
            const images = event.data.attachments
            for (const image in images) {
              if (Object.prototype.hasOwnProperty.call(images, image)) {
                transformedImages[image] = imageModel(images[image])
              }
            }
            dispatch({
              type: 'ATTACHMENTS_GET_SUCCESS',
              attachments: transformedImages
            })
            break
          }

          case 'SALE_INCREMENT_SET':
          case 'SALE_NEXT_BID_SET':
            // sale
            dispatch({
              type: 'LISTING_SALE_GET_SUCCESS',
              sale: parseSale(event.data.sale, isWebcast),
              timestamp
            })
            break

          case 'WEBCAST_INPLAY_SET':
          case 'WEBCAST_INPLAY_STATE_SET':
          case 'WEBCAST_INPLAY_WARNING':
            // webcastInPlay
            dispatch({
              type: 'WEBCAST_INPLAY_GET_SUCCESS',
              inPlay: event.data,
              timestamp
            })
            break

          case 'WEBCAST_FEED_SET':
            // feed
            dispatch({
              type: 'MEDIA_FEED_GET_SUCCESS',
              feed: event.data,
              timestamp
            })
            break

          case 'WEBCAST_MESSAGE_SET':
            {
              // webcastMessage
              const message = Object.assign({}, event.data.message, {
                text: decodeEntities(event.data.message.text)
              })
              dispatch({
                type: 'WEBCAST_MESSAGE_GET_SUCCESS',
                auctionUuid: event.data.auctionUuid,
                message
              })
              if (
                message.popup &&
                message.text &&
                (!message.userUuid || message.userUuid === userUuid)
              ) {
                toast.info(message.text, {
                  autoClose: 30000
                })
              }
            }
            break

          case 'PONG':
            console.timeEnd('Ping Response Time')
            break

          default:
            console.warn('Received unrecognised event', event)
            break
        }

        return dispatch({
          type: 'WEBSOCKET_EVENT_SUCCESS',
          event
        })
      } catch (err) {
        dispatch({
          type: 'WEBSOCKET_EVENT_FAILED',
          event,
          err
        })
        throw err
      }
    }
  },
  auctionGet: (auctionUuid) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'AUCTION_GET'
      })
      const isMarketplace = auctionUuid === 'marketplace'
      const auctionUrl = isMarketplace
        ? 'marketplace'
        : `auctions/${auctionUuid}`
      return APIRequest.get(apiBase, clientId, auctionUrl, null, 3)
        .then(async (res) => {
          const payload = isMarketplace ? res.body.marketplaceDetails : res.body
          const {
            listings,
            auction,
            registrants,
            saleInformation,
            configuration,
            categories,
            starredItems
          } = payload.information
          if (!auction) throw new Error('Auction not returned')

          const isWebcast = auction.webcast

          const partneredAuctions = isMarketplace ? res.body.uuids : null

          const resAttachments = payload.attachments
          // imageModel
          const attachments = {}
          for (const attachment in resAttachments) {
            if (
              Object.prototype.hasOwnProperty.call(resAttachments, attachment)
            ) {
              attachments[attachment] = imageModel(resAttachments[attachment])
            }
          }

          dispatch({
            type: 'AUCTION_GET_SUCCESS',
            auction: Object.assign({}, auction, {
              uuid: isMarketplace ? 'marketplace' : auction.uuid
            })
          })

          dispatch({
            type: 'ATTACHMENTS_GET_SUCCESS',
            attachments
          })

          const { bids, saleStatuses, sales, offers, maxBids } =
            payload.sellingInformation
          const { logoUuid } = auction

          // logo
          if (logoUuid) {
            dispatch({
              type: 'AUCTION_LOGO_GET_SUCCESS',
              auctionUuid,
              auctionLogo: logoUuid
            })
          }
          // partedAuctions
          if (partneredAuctions) {
            dispatch({
              type: 'PARTNERED_AUCTIONS_GET_SUCCESS',
              partneredAuctions
            })
          }

          // bid
          if (bids) {
            if (isWebcast) {
              for (const bid in bids) {
                if (Object.prototype.hasOwnProperty.call(bids, bid)) {
                  bids[bid] = parseBid(bids[bid], isWebcast)
                }
              }
            }
            dispatch({
              type: 'BIDS_GET_SUCCESS',
              bids
            })
          } else {
            dispatch({
              type: 'BIDS_GET_FAILED'
            })
            console.warn('bids not included')
          }

          // saleStatus
          if (saleStatuses) {
            dispatch({
              type: 'SALE_STATUSES_GET_SUCCESS',
              saleStatuses: saleStatuses
            })
          } else {
            dispatch({
              type: 'SALE_STATUSES_GET_FAILED'
            })
            console.warn('saleStatus not included')
          }

          // listings
          if (listings) {
            dispatch({
              type: 'LISTINGS_GET_SUCCESS',
              isMarketplace,
              listings,
              auctionUuid
            })
          } else {
            dispatch({
              type: 'LISTINGS_GET_FAILED'
            })
            console.warn('lots not included')
          }
          if (saleInformation) {
            const saleInformationWithLogo = Object.assign({}, saleInformation, {
              auctionLogo: get(auction, 'logoUuid'),
              locationLongitude: saleInformation.locationLongitude
                ? parseFloat(saleInformation.locationLongitude)
                : null,
              locationLatitude: saleInformation.locationLatitude
                ? parseFloat(saleInformation.locationLatitude)
                : null
            })
            dispatch({
              type: 'AUCTION_INFO_GET_SUCCESS',
              auctionUuid,
              information: saleInformationWithLogo
            })
          } else {
            console.warn('saleInformation not included')
            dispatch({
              type: 'AUCTION_INFO_GET_FAILED'
            })
          }
          if (registrants) {
            dispatch({
              type: 'REGISTRANTS_GET_SUCCESS',
              auctionUuid: auction.uuid,
              registrants
            })
          } else {
            dispatch({
              type: 'REGISTRANTS_GET_FAILED'
            })
            console.warn('registrants not included')
          }
          if (categories) {
            dispatch({
              type: 'CATEGORIES_GET_SUCCESS',
              auctionUuid,
              categories
            })
          } else {
            console.warn('categories not included')
          }
          if (starredItems) {
            dispatch({
              type: 'LISTING_FAVOURITES_GET_SUCCESS',
              auctionUuid,
              favourites: starredItems
            })
          }
          if (sales) {
            if (isWebcast) {
              for (const sale in sales) {
                if (Object.prototype.hasOwnProperty.call(sales, sale)) {
                  sales[sale] = parseSale(sales[sale], isWebcast)
                }
              }
            }
            dispatch({
              type: 'LISTING_SALES_GET_SUCCESS',
              sales
            })
          } else {
            console.warn('sales not included')
          }

          if (offers) {
            dispatch({
              type: 'OFFERS_GET_SUCCESS',
              offers
            })
          }
          if (maxBids) {
            dispatch({
              type: 'MAX_BIDS_GET_SUCCESS',
              maxBids: Object.values(maxBids).reduce((acc, val) => {
                acc[val.uuid] = parseMaxBid(val, isWebcast)
                return acc
              }, {})
            })
          }

          if (configuration) {
            dispatch({
              type: 'AUCTION_CONFIG_GET_SUCCESS',
              auctionUuid: auctionUuid,
              configuration
            })
          }

          const webcast = payload.webcastInformation

          if (webcast) {
            const { inPlay, messages, feed } = webcast

            // feed
            if (feed) {
              dispatch({
                type: 'MEDIA_FEED_GET_SUCCESS',
                feed
              })
            }

            // inPlay
            if (inPlay) {
              dispatch({
                type: 'WEBCAST_INPLAY_GET_SUCCESS',
                inPlay
              })
            } else {
              dispatch({
                type: 'WEBCAST_INPLAY_GET_FAILED'
              })
              console.warn('inPlay not included')
            }

            // messages
            if (messages) {
              dispatch({
                type: 'WEBCAST_MESSAGES_GET_SUCCESS',
                auctionUuid: auction.uuid,
                messages
              })
            } else {
              dispatch({
                type: 'WEBCAST_MESSAGES_GET_FAILED'
              })
              console.warn('messages not included')
            }
          }

          if (isMarketplace) {
            return dispatch({
              type: 'AUCTION_GET_SUCCESS',
              auction: Object.assign({}, auction, {
                uuid: isMarketplace ? 'marketplace' : auction.uuid
              }),
              partneredAuctions
            })
          }
        })
        .catch((err) => {
          console.error(err.message, err.status)
          dispatch({
            type: 'AUCTION_GET_FAILED',
            err
          })
          if (err.status === 503 || typeof err.status === 'undefined') {
            dispatch({
              type: 'GLOBAL_NOTIFICATION_ADDED',
              notificationType: 'danger',
              pageNeedsRefreshing: true,
              hasTimer: true,
              icon: faCircleExclamation,
              message: translate('JspPublicCodes_JSP_503_TOO_MANY_REQUESTS_ERROR')
            })
          }
          if (err.status !== 404 && err.status !== 503 && typeof err.status !== 'undefined') {
            dispatch({
              type: 'GLOBAL_NOTIFICATION_ADDED',
              notificationType: 'danger',
              pageNeedsRefreshing: true,
              icon: faCircleExclamation,
              message: translate(
                'JspPublicCodes_JSP_NETWORK_RECONNECTION_ERROR'
              )
            })
          }
          throw err
        })
    }
  },

  uuidGetById:
    (auctionId, itemId = null) =>
      (dispatch, getState) => {
        const { apiBase, clientId } = getState().config.input.config
        dispatch({
          type: 'AUCTIONS_UUID_GET'
        })
        let url = `auctionUuid/${auctionId}`
        if (itemId) {
          url += `/${itemId}`
        }
        return APIRequest.get(apiBase, clientId, url)
          .then((res) => {
            const { auctionUuid, listingUuid } = res.body
            dispatch({
              type: 'AUCTIONS_UUID_GET_SUCCESS',
              auctionUuid,
              listingUuid
            })
            return {
              auctionUuid,
              listingUuid
            }
          })
          .catch((err) => {
            console.error(err)
            dispatch({
              type: 'AUCTIONS_UUID_GET_FAILED',
              err
            })
            throw err
          })
      }
}

export default auctions
