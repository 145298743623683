const initialState = {
  saleStatuses: {}
}

export default function saleStatuses (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'SALE_STATUS_GET_SUCCESS': {
      const existingSaleStatus = state.saleStatuses[action.saleStatus.listingUuid]
      return (existingSaleStatus && action.timestamp < existingSaleStatus.timestamp)
        ? state
        : Object.assign({}, state, {
          saleStatuses: Object.assign({}, state.saleStatuses, {
            [action.saleStatus.listingUuid]: Object.assign({}, action.saleStatus, {
              timestamp: action.timestamp
            })
          })
        })
    }

    case 'SALE_STATUSES_GET_SUCCESS':
      for (const listingUuid in action.saleStatuses) {
        if (Object.prototype.hasOwnProperty.call(action.saleStatuses, listingUuid)) {
          const existingSaleStatus = state.saleStatuses[listingUuid]
          const newTimestamp = action.saleStatuses[listingUuid].timestamp || action.timestamp
          action.saleStatuses[listingUuid] = (existingSaleStatus && newTimestamp < existingSaleStatus.timestamp)
            ? existingSaleStatus
            : Object.assign({}, action.saleStatuses[listingUuid], {
              timestamp: newTimestamp
            })
        }
      }

      return Object.assign({}, state, {
        saleStatuses: Object.assign({}, state.saleStatuses, action.saleStatuses)
      })

    default:
      return state
  }
}
