import { decodeEntities } from '../helpers/stringHelpers'
import { millisToLocaleString } from '../helpers/dateHelpers'
import get from 'lodash.get'

export default class AuctionSaleSummary {
  constructor (auctionSaleSummaryRaw) {
    this.auctionTitle = decodeEntities(auctionSaleSummaryRaw.title)
    this.bidderCount = auctionSaleSummaryRaw.bidderCount
    this.closeDate = get(auctionSaleSummaryRaw, 'closeDate.millis')
    this.closeDateZone = get(auctionSaleSummaryRaw, 'closeDate.chronology.zone.id', null)
    this.closeDateFormatted = this.closeDate ? millisToLocaleString(this.closeDate, this.closeDateZone) : null
    this.currencyCode = get(auctionSaleSummaryRaw, 'currency.code')
    this.id = auctionSaleSummaryRaw.id
    this.itemCount = auctionSaleSummaryRaw.itemCount
    this.notSellingValue = auctionSaleSummaryRaw.notSellingAmount
    this.notSellingValueFormatted = (typeof this.notSellingValue === 'number') ? this.notSellingValue.toLocaleString() : '0'
    this.offerCount = auctionSaleSummaryRaw.pendingOfferCount
    this.soldSellingValue = auctionSaleSummaryRaw.soldSellingAmount
    this.soldSellingValueFormatted = (typeof this.soldSellingValue === 'number') ? this.soldSellingValue.toLocaleString() : '0'
    this.unsoldValue = auctionSaleSummaryRaw.notSoldAmount
    this.unsoldValueFormatted = (typeof this.unsoldValue === 'number') ? this.unsoldValue.toLocaleString() : '0'

    return this
  }

  toJSON () {
    return {
      auctionTitle: this.auctionTitle,
      bidderCount: this.bidderCount,
      closeDate: this.closeDateFormatted,
      currencyCode: this.currencyCode,
      id: this.id,
      itemCount: this.itemCount,
      notSellingValue: this.notSellingValue,
      notSellingValueFormatted: this.notSellingValueFormatted,
      offerCount: this.offerCount,
      soldSellingValue: this.soldSellingValue,
      soldSellingValueFormatted: this.soldSellingValueFormatted,
      unsoldValue: this.unsoldValue,
      unsoldValueFormatted: this.unsoldValueFormatted
    }
  }
}
