export default class User {
  constructor ({
    displayName,
    email,
    forename,
    id,
    publicVendorAdmin: isVendorAdministrator,
    statusId,
    surname,
    username,
    uuid
  }) {
    this.displayName = displayName
    this.email = email
    this.forename = forename
    this.id = id
    this.isVendorAdministrator = isVendorAdministrator
    this.statusId = statusId
    this.surname = surname
    this.username = username
    this.uuid = uuid

    return this
  }

  toJSON () {
    return {
      displayName: this.displayName,
      email: this.email,
      forename: this.forename,
      id: this.id,
      isVendorAdministrator: this.isVendorAdministrator,
      statusId: this.statusId,
      surname: this.surname,
      uuid: this.uuid,
      username: this.username
    }
  }
}
