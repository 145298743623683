import { combineReducers } from 'redux'
import attachments from './attachments'
import auctions from './auctions'
import bids from './bids'
import categories from './categories'
import config from './config'
import countries from './countries'
import invoices from './invoices'
import lots from './lots'
import listingSales from './listingSales'
import maxBids from './maxBids'
import mediaFeed from './mediaFeed'
import notifications from './notifications'
import offers from './offers'
import orders from './orders'
import registrations from './registrations'
import sales from './sales'
import saleStatuses from './saleStatuses'
import saleSummaries from './saleSummaries'
import users from './users'
import webcast from './webcast'

export default combineReducers({
  auctions,
  bids,
  categories,
  config,
  countries,
  attachments,
  invoices,
  lots,
  listingSales,
  maxBids,
  mediaFeed,
  notifications,
  offers,
  orders,
  registrations,
  sales,
  saleStatuses,
  saleSummaries,
  users,
  webcast
})
