import { DateTime as DT } from 'luxon'

export const DateTime = DT

const cachedLocaleStrings = {}
export function millisToLocaleString (timestamp, timezone = 'system', format = { ...DateTime.DATETIME_MED, timeZoneName: 'short' }) {
  if (!timestamp) return null
  const stringKey = `${timestamp}-${timezone}-${format.year}${format.month}${format.day}${format?.timeZoneName ?? ''}`
  const cachedLocaleString = cachedLocaleStrings[stringKey]
  if (cachedLocaleString) return cachedLocaleString
  const newString = DT.fromMillis(timestamp, { zone: timezone }).toLocaleString(format)
  const formattedDatetime = `${newString}`
  cachedLocaleStrings[stringKey] = formattedDatetime
  return formattedDatetime
}

export function ISOToLocaleString (ISO, timezone = 'system', format = DT.DATETIME_MED) {
  if (!ISO) throw new Error('Could not convert an invalid ISO String')
  return DT.fromISO(ISO, { zone: timezone }).toLocaleString(format)
}

export function getLocaleStringFormat () {
  const language = window.navigator.userLanguage || window.navigator.language || 'en-GB'
  const sample = window.Intl ? new Intl.DateTimeFormat(language).format(new Date(1970, 11, 31)) : ''
  const mi = sample.indexOf(12)
  const di = sample.indexOf(31)
  const yi = sample.indexOf(1970)

  const r = []
  r[yi] = 'YYYY'
  r[mi] = 'MM'
  r[di] = 'DD'
  return r.filter(position => position).join('/')
}
