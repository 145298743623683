import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import get from 'lodash.get'
import queryString from 'query-string'

export class ScrollToElement extends React.Component {
  constructor (props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidUpdate (prevProps) {
    const prevPage = prevProps.location && queryString.parse(prevProps.location.search).page
    const page = prevProps.location && queryString.parse(this.props.location.search).page
    if (
      prevProps.location === undefined ||
      this.props.location.pathname !== prevProps.location.pathname ||
      page !== prevPage
    ) {
      const elementIdentifier = get(window.bidjs, 'options.scrollOnNavigationElementId', 'bidlogix-app')
      const elementToScrollTo = document.getElementById(elementIdentifier)
      if (elementToScrollTo) {
        elementToScrollTo.scrollIntoView(true)
      } else {
        window.scrollTo(0, 0)
      }
    }
  }

  render () {
    return null
  }
}

ScrollToElement.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  })
}

export default withRouter(ScrollToElement)
