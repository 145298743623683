import request from 'superagent'
import authHelpers from './authHelpers'

const getApiEndpointWithVersion = (endpoint, version) => {
  return `/v${version || 1}/${endpoint}`
}

const addAuthentication = (req) => {
  // add in the token if we have one
  const token = authHelpers.getToken()
  if (token) {
    req.set('Authorization', `Bearer ${token}`)
  }
  return req
}

const catchInvalidAuthentication = (err) => {
  if (err.response?.body?.error === 'invalid_token') {
    authHelpers.handleUnauthentication()
    window.location.reload()
  } else {
    throw err
  }
}

const apiHelpers = {
  get: (apiBase, clientId, endpoint, args, version = null, isBlob = false) => {
    const argString = (args) ? `?${args}` : ''
    const req = request.get(apiBase + getApiEndpointWithVersion(endpoint, version) + argString)
      .set('x-forwarded-client-id', clientId)

    if (isBlob) {
      req.responseType('blob')
    }

    return addAuthentication(req)
      .then(res => res)
      .catch(catchInvalidAuthentication)
  },

  post: (apiBase, clientId, endpoint, payload = {}, version = null) => {
    const req = request
      .post(apiBase + getApiEndpointWithVersion(endpoint, version))
      .send(payload)
      .set('x-forwarded-client-id', clientId)

    return addAuthentication(req)
      .then(res => res)
      .catch(catchInvalidAuthentication)
  },

  put: (apiBase, clientId, endpoint, payload = {}, version = null) => {
    const req = request
      .put(apiBase + getApiEndpointWithVersion(endpoint, version))
      .send(payload)
      .set('x-forwarded-client-id', clientId)

    return addAuthentication(req)
      .then(res => res)
      .catch(catchInvalidAuthentication)
  },

  patch: (apiBase, clientId, endpoint, payload = {}, version = null) => {
    const req = request
      .patch(apiBase + getApiEndpointWithVersion(endpoint, version))
      .send(payload)
      .set('x-forwarded-client-id', clientId)

    return addAuthentication(req)
      .then(res => res)
      .catch(catchInvalidAuthentication)
  },

  delete: (apiBase, clientId, endpoint, version = null) => {
    const req = request.delete(apiBase + getApiEndpointWithVersion(endpoint, version))
      .set('x-forwarded-client-id', clientId)

    return addAuthentication(req)
      .then(res => res)
      .catch(catchInvalidAuthentication)
  }
}

export default apiHelpers
