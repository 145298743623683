const initialState = {
  hasErrors: false,
  invoiceList: [],
  invoiceListFiltered: [],
  invoices: {},
  isLoading: false,
  invoicesSearchTerm: '',
  invoiceStatusFilter: null
}

export default function invoices (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  const filterInvoiceByIdOrTitle = (invoice, term = state.invoicesSearchTerm) => {
    const searchTerm = term.toLowerCase()
    return invoice.identifier.toLowerCase().indexOf(searchTerm) === 0 ||
      invoice.title.toLowerCase().includes(searchTerm)
  }

  const filterInvoiceByStatus = (invoice, statusId = state.invoiceStatusFilter) => !statusId || invoice.paymentStatus.id === statusId

  switch (action.type) {
    case 'INVOICE_LIST':
      return Object.assign({}, state, {
        hasErrors: false,
        isLoading: true
      })

    case 'INVOICE_LIST_SUCCESS':
      return Object.assign({}, state, {
        hasErrors: false,
        invoices: Object.assign({}, state.invoices, action.invoices),
        invoiceList: action.invoiceList,
        invoiceListFiltered: (state.invoicesSearchTerm || state.invoiceStatusFilter)
          ? action.invoiceList
            .map(invoiceId => action.invoices[invoiceId])
            .filter(invoice => !!invoice)
            .filter((invoice) => filterInvoiceByStatus(invoice, state.invoiceStatusFilter))
            .filter((invoice) => filterInvoiceByIdOrTitle(invoice))
            .map(invoice => invoice.id)
          : action.invoiceList,
        isLoading: false
      })

    case 'INVOICE_LIST_FAILED':
      return Object.assign({}, state, {
        hasErrors: true,
        isLoading: false
      })

    case 'INVOICE_FILTER':
      return Object.assign({}, state, {
        invoiceStatusFilter: action.statusId,
        invoiceListFiltered: state.invoiceList
          .map(invoiceId => state.invoices[invoiceId])
          .filter(invoice => !!invoice)
          .filter((invoice) => filterInvoiceByStatus(invoice, action.statusId))
          .filter((invoice) => filterInvoiceByIdOrTitle(invoice))
          .map(invoice => invoice.id)
      })

    case 'INVOICE_SEARCH':
      return Object.assign({}, state, {
        invoicesSearchTerm: action.term,
        invoiceListFiltered: state.invoiceList
          .map(invoiceId => state.invoices[invoiceId])
          .filter(invoice => !!invoice)
          .filter((invoice) => filterInvoiceByStatus(invoice))
          .filter((invoice) => filterInvoiceByIdOrTitle(invoice, action.term))
          .map(invoice => invoice.id)
      })

    default:
      return state
  }
}
