import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

const Alert3 = ({ children, className, color, ...props }) => {
  return (
    <Alert className={className} bsStyle={color} {...props}>
      {children}
    </Alert>
  )
}

Alert3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'warning', 'danger', 'info']).isRequired
}

Alert3.defaultProps = {
  color: 'info'
}

export default Alert3
