const initialState = {
  maxBids: {},
  maxBidsByListingUuid: {}
}

export default function maxBids (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'MAX_BID_SET_SUCCESS': {
      return Object.assign({}, state, {
        maxBids: Object.assign({}, state.maxBids, {
          [action.maxBid.uuid]: action.maxBid
        }),
        maxBidsByListingUuid: Object.assign({}, state.maxBidsByListingUuid, {
          [action.maxBid.listingUuid]: action.maxBid
        })
      })
    }

    case 'MAX_BIDS_GET_SUCCESS': {
      const maxBidsByListingUuid = Object.values(action.maxBids).reduce((acc, val) => {
        acc[val.listingUuid] = val
        return acc
      }, {})

      const maxBidsParsed = Object.values(action.maxBids).reduce((acc, val) => {
        acc[val.uuid] = val
        return acc
      }, {})

      return Object.assign({}, state, {
        maxBids: Object.assign({}, state.maxBids, maxBidsParsed),
        maxBidsByListingUuid: Object.assign({}, state.maxBidsByListingUuid, maxBidsByListingUuid)
      })
    }

    case 'MAX_BIDS_RESET':
      return Object.assign({}, state, {
        maxBids: {},
        maxBidsByListingUuid: {},
        isLoading: false
      })

    default:
      return state
  }
}
