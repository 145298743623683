import { decodeEntities } from '../helpers/stringHelpers'

export default class RegistrationInformation {
  constructor (registrationInformationRaw) {
    this.isRegistered = registrationInformationRaw.alreadyRegistered
    this.isSpendingLimitRequired = registrationInformationRaw.spendingLimitRequired
    this.auctionId = registrationInformationRaw.auctionId
    this.auctionTitle = decodeEntities(registrationInformationRaw.auctionTitle)
    this.currencyCode = registrationInformationRaw.currency.code
    this.registrationText = decodeEntities(registrationInformationRaw.registrationText)
    this.terms = decodeEntities(registrationInformationRaw.auctionTerms)

    return this
  }

  toJSON () {
    return {
      isRegistered: this.isRegistered,
      isSpendingLimitRequired: this.isSpendingLimitRequired,
      auctionId: this.auctionId,
      auctionTitle: this.auctionTitle,
      currencyCode: this.currencyCode,
      registrationText: this.registrationText,
      terms: this.terms
    }
  }
}
