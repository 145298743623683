import { decodeEntities } from '../helpers/stringHelpers'

export default class Order {
  constructor (orderRaw) {
    this.identifier = orderRaw.lotNumber
    this.id = orderRaw.id
    this.title = decodeEntities(orderRaw.title)
    this.acceptedOffer = {
      currency: orderRaw.currencyCode,
      value: orderRaw.amount
    }
    this.acceptedOfferFormatted = (typeof orderRaw.amount === 'number')
      ? orderRaw.amount.toLocaleString(undefined, {
        style: 'currency',
        currency: orderRaw.currencyCode,
        currencyDisplay: 'symbol'
      })
      : '0'

    this.orderDelivery = {
      hasDeliveryMethod: 'collection',
      isCompleted: orderRaw.collected
    }
    this.partOfInvoice = orderRaw.invoiceId

    return this
  }

  toJSON () {
    return {
      identifier: this.identifier,
      id: this.id,
      title: this.title,
      acceptedOffer: this.acceptedOffer,
      acceptedOfferFormatted: this.acceptedOfferFormatted,
      orderDelivery: this.orderDelivery,
      partOfInvoice: this.partOfInvoice
    }
  }
}
