import { decodeHTML } from 'entities'
import i18n from './i18n'

export function capitalise (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const decodedStrings = {}
export function decodeEntities (stringToDecode = null) {
  const cachedString = decodedStrings[stringToDecode]
  if (cachedString) return cachedString
  if (stringToDecode === null || stringToDecode === undefined) return null
  const decodedString = decodeHTML(stringToDecode)
  decodedStrings[stringToDecode] = decodedString
  return decodedString
}

// we memoize this method, as it can be called multiple timed during render comparisons
// this will mean that we don't ned to run decoding or translation multiple times for a single string
const translatedStrings = {}
export function translate (stringToTranslate, args = null) {
  if (!args && stringToTranslate in translatedStrings) {
    return translatedStrings[stringToTranslate]
  } else {
    const translatedString = decodeEntities(i18n.t(stringToTranslate, args))
    translatedStrings[stringToTranslate] = translatedString
    return translatedString
  }
}
