import { decodeEntities } from '../helpers/stringHelpers'

const initialState = {
  favourites: {},
  filteredLotIdsByVendor: {},
  hasErrors: false,
  isLoading: false,
  listingsByAuctionByCategoryUuid: {},
  listingsByAuctionByLocation: {},
  listings: {},
  lotsDeprecated: {},
  lotIdsByVendor: {},
  lotSearchTerm: null,
  lotStateFilter: 0
}

export default function lots (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  const filterLotIdByTitleOrRef = (lotId, term = state.lotSearchTerm) => {
    if (!term) return true
    const lot = (action.lots) ? action.lots[lotId] : state.lotsDeprecated[lotId]
    if (lot) {
      return (lot.lotNumber.toString().indexOf(term) === 0 || lot.title.toLowerCase().includes(term.toLowerCase()))
    }
    return false
  }

  const filterLotIdByLotStatus = (lotId, desiredLotState = state.lotStateFilter) => {
    if (!desiredLotState) return true
    const lot = (action.lots) ? action.lots[lotId] : state.lotsDeprecated[lotId]
    if (lot) {
      return (`${lot.saleStatusId}` === `${desiredLotState}`)
    }
    return false
  }

  const decodeListingProperties = (lot) => (Object.assign({}, lot, {
    description: decodeEntities(lot.description),
    title: decodeEntities(lot.title),
    summary: decodeEntities(lot.summary),
    countryCode: lot.countryCode,
    address: {
      address: lot.address?.address || null,
      locationLatitude: lot.address?.locationLatitude ? parseFloat(lot.address.locationLatitude) : null,
      locationLongitude: lot.address?.locationLongitude ? parseFloat(lot.address.locationLongitude) : null
    }
  }))

  switch (action.type) {
    case 'LISTINGS_GET_SUCCESS': {
      for (const listingUuid in action.listings) {
        if (Object.prototype.hasOwnProperty.call(action.listings, listingUuid)) {
          const existingListings = state.listings[listingUuid]
          action.listings[listingUuid] = (existingListings && action.timestamp < existingListings.timestamp)
            ? existingListings
            : Object.assign({}, decodeListingProperties(action.listings[listingUuid]), {
              timestamp: action.timestamp,
              isMarketplace: action.isMarketplace
            })
        }
      }
      const listingsByCategory = Object.values(action.listings).reduce((acc, val) => {
        if (!acc[val.categoryUuid]) {
          acc[val.categoryUuid] = []
        }
        acc[val.categoryUuid].push(val.uuid)
        return acc
      }, {})
      const listingsByLocation = Object.values(action.listings).reduce((acc, val) => {
        const countryCode = val.countryCode
        if (!acc[countryCode]) {
          acc[countryCode] = []
        }
        acc[countryCode].push(val.uuid)
        return acc
      }, {})
      return Object.assign({}, state, {
        listingsByAuctionByCategoryUuid: Object.assign({}, state.listingsByAuctionByCategoryUuid, {
          [action.auctionUuid]: listingsByCategory
        }),
        listingsByAuctionByLocation: Object.assign({}, state.listingsByAuctionByLocation, {
          [action.auctionUuid]: listingsByLocation
        }),
        listings: Object.assign({}, state.listings, action.listings)
      })
    }

    case 'LOTS_LIST_DEPRECATED':
      return Object.assign({}, state, {
        isLoading: true
      })

    case 'LOTS_LIST_DEPRECATED_SUCCESS':
      return Object.assign({}, state, {
        lotsDeprecated: Object.assign({}, state.lotsDeprecated, action.lots),
        isLoading: false
      })

    case 'LOTS_LIST_DEPRECATED_FAILED':
      return Object.assign({}, state, {
        hasErrors: true,
        isLoading: false
      })

    case 'LISTING_FAVOURITES_GET_SUCCESS':
      return Object.assign({}, state, {
        favourites: Object.assign({}, state.favourites, {
          [action.auctionUuid]: action.favourites.reduce((acc, val) => {
            acc[val] = true
            return acc
          }, {})
        })
      })

    case 'LISTING_FAVOURITE_TOGGLE_SUCCESS':
      return Object.assign({}, state, {
        favourites: Object.assign({}, state.favourites, {
          [action.auctionUuid]: Object.assign({}, state.favourites[action.auctionUuid] || {}, {
            [action.listingUuid]: action.isFavourited
          })
        })
      })

    case 'LOT_SEARCH': {
      const filteredLotIdsByVendor = {}

      for (const vendorId in state.lotIdsByVendor) {
        if (Object.prototype.hasOwnProperty.call(state.lotIdsByVendor, vendorId)) {
          filteredLotIdsByVendor[vendorId] = state.lotIdsByVendor[vendorId]
            .filter((lotId) => filterLotIdByTitleOrRef(lotId, action.searchTerm))
            .filter((lotId) => filterLotIdByLotStatus(lotId))
        }
      }

      return Object.assign({}, state, {
        lotSearchTerm: action.searchTerm,
        filteredLotIdsByVendor
      })
    }

    case 'LOT_STATE_FILTER': {
      const filteredLotIdsByVendor = {}

      for (const vendorId in state.lotIdsByVendor) {
        if (Object.prototype.hasOwnProperty.call(state.lotIdsByVendor, vendorId)) {
          filteredLotIdsByVendor[vendorId] = state.lotIdsByVendor[vendorId]
            .filter((lotId) => filterLotIdByTitleOrRef(lotId))
            .filter((lotId) => filterLotIdByLotStatus(lotId, action.statusId))
        }
      }

      return Object.assign({}, state, {
        lotStateFilter: action.statusId,
        filteredLotIdsByVendor
      })
    }

    case 'VENDOR_LOTS_LIST_SUCCESS': {
      const lotIdsByVendor = Object.assign({}, state.lotIdsByVendor, action.lotIdsByVendor)
      const filteredLotIdsByVendor = {}

      for (const vendorId in lotIdsByVendor) {
        if (Object.prototype.hasOwnProperty.call(lotIdsByVendor, vendorId)) {
          filteredLotIdsByVendor[vendorId] = lotIdsByVendor[vendorId]
            .filter((lotId) => filterLotIdByTitleOrRef(lotId))
            .filter((lotId) => filterLotIdByLotStatus(lotId))
        }
      }

      return Object.assign({}, state, {
        filteredLotIdsByVendor,
        lotIdsByVendor: lotIdsByVendor
      })
    }

    default:
      return state
  }
}
