import { decodeEntities } from '../helpers/stringHelpers'

export default class Lot {
  constructor (lotRaw) {
    this.id = lotRaw.id || lotRaw.itemId
    this.lotNumber = lotRaw.lotNumber
    this.title = decodeEntities(lotRaw.title)
    this.description = lotRaw.description ? decodeEntities(lotRaw.description) : ''
    this.summary = lotRaw.summary ? decodeEntities(lotRaw.summary) : ''
    this.currencyCode = lotRaw.currencyCode
    this.saleStatusId = lotRaw.saleStatusId
    this.statusId = lotRaw.statusId
    this.value = lotRaw.amount
    this.valueFormatted = (typeof this.value === 'number') ? this.value.toLocaleString() : '0'

    return this
  }

  toJSON () {
    return {
      id: this.id,
      lotNumber: this.lotNumber,
      title: this.title,
      description: this.description,
      summary: this.summary,
      currencyCode: this.currencyCode,
      saleStatusId: this.saleStatusId,
      statusId: this.statusId,
      value: this.value,
      valueFormatted: this.valueFormatted
    }
  }
}
