import React from 'react'
import PropTypes from 'prop-types'

const TextBlock3 = ({
  children,
  className,
  color,
  float,
  small,
  tag: Tag
}) => {
  let appendedClassName = className
  if (color) appendedClassName += ` text-${color}`
  if (float) appendedClassName += ` pull-${float}`
  return (
    <Tag className={appendedClassName}>
      {small ? <small>{children}</small> : children}
    </Tag>
  )
}

TextBlock3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'muted', 'primary']),
  float: PropTypes.oneOf(['left', 'right']),
  tag: PropTypes.string,
  small: PropTypes.bool
}

TextBlock3.defaultProps = {
  className: '',
  tag: 'p'
}

export default TextBlock3
