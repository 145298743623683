const initialState = {
  attachments: {}
}

export default function attachments (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'ATTACHMENTS_GET_SUCCESS':
      return Object.assign({}, state, {
        attachments: Object.assign({}, state.attachments, action.attachments)
      })

    default:
      return state
  }
}
