const initialState = {
  bids: {}
}

export default function bids (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'BID_GET_SUCCESS': {
      const existingBid = state.bids[action.bid.uuid]
      return (existingBid && action.timestamp < existingBid.timestamp)
        ? state
        : Object.assign({}, state, {
          bids: Object.assign({}, state.bids, {
            [action.bid.uuid]: Object.assign({}, action.bid, {
              timestamp: action.timestamp
            })
          })
        })
    }

    case 'BIDS_GET_SUCCESS':
      return Object.assign({}, state, {
        bids: Object.assign({}, state.bids, action.bids)
      })

    default:
      return state
  }
}
