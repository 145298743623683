import { decodeEntities } from '../helpers/stringHelpers'

const imageModel = ({ uuid, label, versionAndPublicId }) => versionAndPublicId
  ? ({
      uuid,
      title: decodeEntities(label),
      publicId: versionAndPublicId
    })
  : null

export default imageModel
