const initialState = {
  currentLanguageCode: null,
  input: {},
  hasErrors: false,
  options: {},
  loading: false,
  timeOffset: 0,
  translations: {},
  uid: null
}

export default function config (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'CONFIG_TRANSLATIONS_GET':
    case 'CONFIG_MODELS_GET':
      return Object.assign({}, state, {
        hasErrors: false,
        loading: true
      })
    case 'CONFIG_TRANSLATIONS_GET_SUCCESS':
      return Object.assign({}, state, {
        hasErrors: false,
        loading: false,
        translations: action.translations
      })
    case 'CONFIG_MODELS_GET_SUCCESS':
      return Object.assign({}, state, {
        currentLanguageCode: action.currentLanguageCode,
        hasErrors: false,
        loading: false,
        options: action.options,
        timeOffset: action.timeOffset,
        translations: action.translations,
        uid: action.uid
      })
    case 'CONFIG_TRANSLATIONS_GET_FAILED':
      return Object.assign({}, state, {
        hasErrors: true,
        loading: false
      })
    case 'CONFIG_MODELS_GET_FAILED':
      return Object.assign({}, state, {
        hasErrors: true,
        loading: false
      })

    default:
      return state
  }
}
