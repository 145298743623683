const trackingEvents = {
  CATEGORY_AUCTION: 'Auction',
  CATEGORY_INVOICE: 'Invoice',
  CATEGORY_LISTING: 'Listing',
  CATEGORY_SEARCH: 'Search',
  CATEGORY_USER: 'User',
  ACTION_CHANGE_LAYOUT: 'bidJS_action__layout--change',
  ACTION_CHANGE_PAGESIZE: 'bidJS_action__pagination--change-size',
  ACTION_CREATE_ACCOUNT: 'bidJS_action__sign_up',
  ACTION_LOGIN: 'bidJS_action__login',
  ACTION_LOGIN_FAILED: 'bidJS_action__login_failed',
  ACTION_LOGOUT: 'bidJS_action__logout',
  ACTION_REGISTER: 'bidJS_action__auction_register',
  ACTION_TOGGLE_VIDEO: 'bidJS_action__video_toggle',
  ACTION_FILTER_SEARCH: 'bidJS_action__search',
  ACTION_FILTER_OPEN: 'bidJS_action__filter_open',
  ACTION_FILTER_STARRED: 'bidJS_action__filter_starred',
  ACTION_FILTER_CATEGORY: 'bidJS_action__filter_category',
  ACTION_FILTER_COUNTRY: 'bidJS_action__filter_country',
  ACTION_FILTER_STATUS_ALL: 'bidJS_action__filter_status--all',
  ACTION_FILTER_STATUS_WINNING: 'bidJS_action__filter_status--winning',
  ACTION_FILTER_STATUS_LOSING: 'bidJS_action__filter_status--losing',
  ACTION_SEARCH_INPUT: 'bidJS_action__search_input',
  ACTION_VIEW_PAGE: 'bidJS_action__pagination_view',
  ACTION_BID: 'bidJS_action__bid',
  ACTION_AUTOBID: 'bidJS_action__autoBid',
  ACTION_BUY: 'bidJS_action__buy',
  ACTION_OFFER: 'bidJS_action__offer',
  ACTION_TENDER: 'bidJS_action__tender',
  ACTION_TENDER_PCT: 'bidJS_action__tender_PCT',
  ACTION_STAR: 'bidJS_action__star',
  ACTION_CONTACT_SELLER: 'bidJS_action__contact_seller',
  ACTION_BROWSE_RELATED: 'bidJS_action__browse_related',
  ACTION_BID_CONFIRM_OPT_OUT: 'bidJS_action__bid_confirm_opt_out',
  MODAL_TERMS: 'Terms and Conditions',
  MODAL_AUCTION_TERMS: 'Auction Terms and Conditions',
  MODAL_SALE_INFO: 'Sale Information',
  MODAL_CONTACT_SELLER: 'Contact Seller',
  MODAL_BID_CONFIRM: 'Bid Confirm',
  MODAL_AUTOBID_CONFIRM: 'Autobid Confirm',
  MODAL_BUY_CONFIRM: 'Buy Confirm',
  MODAL_OFFER_CONFIRM: 'Offer Confirm',
  MODAL_TENDER_CONFIRM: 'Tender Confirm',
  MODAL_GALLERY: 'Listing Image Gallery',
  MODAL_PRIVACY: 'Privacy',
  MODAL_COLLECTION: 'Collection Details',
  MODAL_PAYMENT: 'Payment Information',
  MODAL_REMOVAL: 'Removal Information',
  MODAL_CONTACT_AUCTION: 'Contact Auction Information',
  MODAL_BID_HISTORY: 'Bid History'
}

export default trackingEvents
