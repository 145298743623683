import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { matchPath } from 'react-router'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { get } from 'lodash'

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import App from './App'
import { init } from './withTracking'

import './helpers/i18n'
import routeHelpers from './helpers/routeHelpers'
import { isValidError } from './helpers/sentryHelpers'

const Search = React.lazy(() => import('./Search'))

// Sentry error reporting
const sentryDSN = process.env.REACT_APP_SENTRY_DSN
const opts = window.bidjs
const history = routeHelpers.initialiseHistory(opts.options?.urlPattern || 'hash')
const routes = [
  { path: '/auctions/:auctionUuid/register' },
  { path: '/mySales/:auctionId/(stats|lots)' },
  { path: '/auctions/:auctionUuid/listings/:listingUuid' },
  { path: '/auctions/:auctionUuid' }
]
if (sentryDSN) {
  const serverEnvironment = opts.config.isStaging && opts.config.region !== 'local' ? '.staging' : ''
  const serverSuffix = `${opts.config.region}${serverEnvironment}.bidjs.com`
  const apiBase = `https://${opts.config.server}.${serverSuffix}/auction-007/api`
  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_PACKAGE_VERSION,
    replaysSessionSampleRate: 0.1,
    replayOnErrorSampleRate: 1.0,
    ignoreUrls: [
      // Removing from reports, throws an excessive amount of errors that are not related to bidjs
      /tes-amm.com/,
      // Ignore any javascript errors coming from WordPress scripts.
      // WordPress scripts throw many errors around the hashbang but doesn't affect embedded bidjs functionality.
      /\/wp-content\//,
      /\/wp-includes\//,
      // Ignore any errors originating from core jquery scripts
      // In many bidjs implementations jQuery often trips over the #! hashbang.  Doesn't affect embedded bidjs.
      /\/jquery\.js/,
      /\/jquery-ui\.js/
    ],
    ignoreErrors: [
      // Specific bidjs user errors (4.x.x) that do not need to be reported as application errors
      /You must be logged in/i,
      /Incorrect Credentials/i,
      /Unable to activate you're account - please try again late/i,
      /was beaten by another bid/i,
      // Ignore all other user request 4.x.x errors.
      /"status":"400"/i,
      // Ignore any transient connection errors encountered by users, or generated by a monolith backend outage.
      // Single line version of the generic user network connectivity issue message.
      /Request has been terminated Possible causes: the network is offline/i,
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      // Browsers configured to block third party cookie produce this but has no effect on bidjs.
      "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/24
      'Object Not Found Matching Id',
      "Failed to read the 'rules' property from 'CSSStyleSheet': Cannot access rules",
      'SecurityError: Not allowed to access cross-origin stylesheet',
      // This covers multiline versions of the user network connection errors.
      'Request has been terminated\n Possible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.',
      'amplitude is not defined',
      'Can/t find variable: amplitude',
      /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.*/i
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    integrations: [
      new Sentry.Replay(),
      new Integrations.BrowserTracing({
        tracingOrigins: [apiBase],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        )
      })
    ],
    tracesSampleRate:
      process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production' ? 0.01 : 0,
    beforeSend (event) {
      const framesArray =
        event?.exception?.values?.[0]?.stacktrace?.frames ||
        event?.values?.[0]?.stacktrace?.frames
      if (event.tags?.hasBrokenUI) return event
      if (framesArray && isValidError(framesArray)) return event
      return null
    }
  })
}

const analyticsCodes = ['G-TPK9MQ79L7', 'UA-46402216-1']

const inputOptions = window.bidjs.options
if (inputOptions) {
  // if IE, and an unsupported browser message is defined, alert
  if (inputOptions.unsupportedBrowserMessage && window.document.documentMode) {
    window.alert(inputOptions.unsupportedBrowserMessage)
  }

  if (inputOptions.analyticsCode) {
    analyticsCodes.push(inputOptions.analyticsCode)
  }
}
const targetElement = document.getElementById('bidjs')
const searchElement = document.getElementById('bidjs__search')
const renderApp = () => {
  if (targetElement) {
    ReactDOM.render(<App history={history} />, targetElement)
  } else {
    console.warn('Could not find "#bidjs" element to render into')
  }
  if (searchElement) {
    ReactDOM.render((<Suspense fallback=''><Search history={history} /></Suspense>), searchElement)
  }
}
init(analyticsCodes, [
  { key: 'bidJS_tenant', value: get(opts, 'config.clientId') },
  { key: 'bidJS_server', value: get(opts, 'config.server') },
  { key: 'bidJS_version', value: process.env.REACT_APP_PACKAGE_VERSION },
  { key: 'app_version', value: process.env.REACT_APP_PACKAGE_VERSION },
  { key: 'bidJS_options_allowTitleChange', value: get(inputOptions, 'allowTitleChange', true) },
  { key: 'bidJS_options_disableBidHistory', value: get(inputOptions, 'disableBidHistory', false) },
  { key: 'bidJS_options_disableBranding', value: get(inputOptions, 'disableBranding', false) },
  { key: 'bidJS_options_disableConsecutiveBidding', value: get(inputOptions, 'disableConsecutiveBidding', false) },
  { key: 'bidJS_options_imageCropMode', value: get(inputOptions, 'imageCropMode', 'fill') },
  { key: 'bidJS_options_isOAuthEnabled', value: get(inputOptions, 'oAuth.loginUrl', false) },
  { key: 'bidJS_options_isWebcastVideoCentric', value: get(inputOptions, 'isWebcastVideoCentric', false) },
  { key: 'bidJS_options_scrollOnNavigationElement', value: get(inputOptions, 'scrollOnNavigationElementId', false) },
  { key: 'bidJS_options_setMarketplaceDefault', value: get(inputOptions, 'setMarketplaceDefault', false) },
  { key: 'bidJS_options_urlPattern', value: get(inputOptions, 'urlPattern', 'hash') }
]).then(() => {
  if (process.env.NODE_ENV !== 'production') {
    import('@axe-core/react').then(axe => {
      axe.default(React, ReactDOM, 1000)
      renderApp()
    })
  } else {
    renderApp()
  }
})
