import { decodeEntities } from '../helpers/stringHelpers'

export default class VendorStats {
  constructor (vendorInput, isWebcast) {
    this.vendorName = decodeEntities(vendorInput.name)
    this.id = vendorInput.id
    this.lotCount = vendorInput.itemCount
    this.lotCountFormatted = (typeof this.lotCount === 'number') ? this.lotCount.toLocaleString() : '0'
    this.bidCount = vendorInput.bidCount
    this.bidCountFormatted = (typeof this.bidCount === 'number') ? this.bidCount.toLocaleString() : '0'
    this.currencyCode = vendorInput.currencyCode
    this.prebidValue = vendorInput.prebidAmount
    this.prebidValueFormatted = (typeof this.prebidValue === 'number')
      ? isWebcast
        ? (this.prebidValue / 100).toLocaleString()
        : this.prebidValue.toLocaleString()
      : '0'
    this.sellingValue = vendorInput.sellingAmount
    this.sellingValueFormatted = (typeof this.sellingValue === 'number') && !isWebcast
      ? this.sellingValue.toLocaleString()
      : '0'
    this.notSellingValue = vendorInput.notSellingAmount
    this.notSellingValueFormatted = (typeof this.notSellingValue === 'number') && !isWebcast
      ? this.notSellingValue.toLocaleString()
      : '0'
    this.soldValue = vendorInput.soldAmount
    this.soldValueFormatted = (typeof this.soldValue === 'number')
      ? isWebcast
        ? (this.soldValue / 100).toLocaleString()
        : this.soldValue.toLocaleString()
      : '0'
    this.unsoldValue = vendorInput.unsoldAmount
    this.unsoldValueFormatted = (typeof this.unsoldValue === 'number')
      ? isWebcast
        ? (this.unsoldValue / 100).toLocaleString()
        : this.unsoldValue.toLocaleString()
      : '0'
    this.lots = vendorInput.listOfItems.map(lot => lot.id)

    return this
  }

  toJSON () {
    return {
      vendorName: this.vendorName,
      lotCount: this.lotCount,
      lotCountFormatted: this.lotCountFormatted,
      bidCount: this.bidCount,
      bidCountFormatted: this.bidCountFormatted,
      currencyCode: this.currencyCode,
      id: this.id,
      lots: this.lots,
      prebidValue: this.prebidValue,
      prebidValueFormatted: this.prebidValueFormatted,
      sellingValue: this.sellingValue,
      sellingValueFormatted: this.sellingValueFormatted,
      notSellingValue: this.notSellingValue,
      notSellingValueFormatted: this.notSellingValueFormatted,
      soldValue: this.soldValue,
      soldValueFormatted: this.soldValueFormatted,
      unsoldValue: this.unsoldValue,
      unsoldValueFormatted: this.unsoldValueFormatted
    }
  }
}
