import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import queryString from 'query-string'

import ErrorUnauthorized from '../../modules/errorUnauthorised'
import { isAbsoluteURL } from '../../helpers/routeHelpers'

const ProtectedRoute = ({ component: Component, ...remainingProps }) => (
  <Route
    {...remainingProps}
    render={renderProps => {
      if (remainingProps.hasAccess) {
        return <Component {...renderProps} />
      }
      const { redirect } = queryString.parse(remainingProps.location.search)
      const redirectTo = remainingProps.redirectTo || redirect
      if (redirectTo) {
        const { hostname, pathname } = window.location
        const isAbsolute = isAbsoluteURL(redirectTo) && !redirectTo.includes(hostname, pathname)
        if (isAbsolute) {
          window.location.replace(redirectTo)
          return null
        }
        const redirectParts = redirectTo.split('#!')
        return <Redirect to={redirectParts[redirectParts.length - 1]} />
      }
      return <ErrorUnauthorized />
    }}
  />
)

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }),
  redirectTo: PropTypes.string
}

ProtectedRoute.defaultProps = {
  hasAccess: false
}

export default ProtectedRoute
