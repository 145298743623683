import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'

import { config, users } from '../../actions'
import Loading from '../../components/loading'
import authHelpers from '../../helpers/authHelpers'
import { translate } from '../../helpers/stringHelpers'
import ConfigContext from '../../contexts/configContext'

const getCookie = authHelpers.getCookie
const getToken = authHelpers.getToken

export class WithBaseModels extends Component {
  static contextType = ConfigContext

  constructor () {
    super()
    this.state = {
      isAuthenticating: false
    }
  }

  async componentDidMount () {
    if (!this.props.hasLoaded && !this.props.isLoading && !this.props.hasErrors) {
      // If OAuth is enabled for the client, validate any existing third party token before getting baseModels
      if (this.props.hasOAuthEnabled) {
        const { isTokenLocalStorage, tokenName } = this.props.OAuthConfig
        const thirdPartyToken = (isTokenLocalStorage) ? getToken(tokenName) : getCookie(tokenName)
        if (thirdPartyToken && !this.props.isLoggedIn && !this.state.isAuthenticating) {
          this.setState({
            isAuthenticating: true
          })
          try {
            const action = await this.props.getOAuthToken(thirdPartyToken)
            authHelpers.handleAuthentication(action.token)
          } catch (err) {
            console.error(err)
            toast.error(err.message)
          }
          this.setState({
            isAuthenticating: false
          })
        }
      }
      this.props.getBaseModels()
    }
  }

  render () {
    const {
      imageCropMode,
      imageGravity,
      imagePadColor,
      isLoginModuleEnabled,
      locale,
      mediaCDN
    } = this.context

    return (this.props.isLoading || !this.props.hasLoaded)
      ? <div className='page__loader'><Loading /></div>
      : (
        <div>
          <Helmet>
            {this.props.hasCustomTitle
              ? <title>{translate('SEOCodes_SITE_SEO_DEFAULT_TITLE')}</title>
              : null}
            {this.props.hasCustomMetaDescription
              ? <meta name='description' content={translate('SEOCodes_SITE_SEO_DEFAULT_META_DESCRIPTION')} />
              : null}
          </Helmet>
          <ConfigContext.Provider value={{
            locale,
            hasCustomTitle: this.props.hasCustomTitle,
            hasCustomMetaDescription: this.props.hasCustomMetaDescription,
            mediaCDN: this.props.mediaCDN || mediaCDN,
            imageGravity: this.props.imageGravity || imageGravity,
            imageCropMode: this.props.imageCropMode || imageCropMode,
            imagePadColor: this.props.imagePadColor || imagePadColor,
            isLoginModuleEnabled: this.props.isLoginModuleEnabled || isLoginModuleEnabled,
            timeOffset: this.props.timeOffset
          }}
          >
            {this.props.children}
          </ConfigContext.Provider>
          {this.props.isBrandingEnabled
            ? (
            <p id='app__branding' className='text-right text-muted'>
              <small dangerouslySetInnerHTML={{
                __html: translate('JspPublicCodes_JSP_BIDJS_BRANDING')
              }}
              />
            </p>
              )
            : null}
        </div>
        )
  }
}

WithBaseModels.propTypes = {
  children: PropTypes.node,
  getBaseModels: PropTypes.func.isRequired,
  getOAuthToken: PropTypes.func.isRequired,
  hasCustomTitle: PropTypes.bool.isRequired,
  hasCustomMetaDescription: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  hasOAuthEnabled: PropTypes.bool.isRequired,
  mediaCDN: PropTypes.string,
  imageCropMode: PropTypes.string,
  imageGravity: PropTypes.string,
  imagePadColor: PropTypes.string,
  isBrandingEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isLoginModuleEnabled: PropTypes.bool,
  OAuthConfig: PropTypes.shape({
    isTokenLocalStorage: PropTypes.bool,
    tokenName: PropTypes.string
  }),
  timeOffset: PropTypes.number
}

WithBaseModels.defaultProps = {
  isBrandingEnabled: false
}

export function mapStateToProps (state) {
  return {
    hasCustomTitle: !!state.config.input.options.allowTitleChange,
    hasCustomMetaDescription: !!state.config.input.options.allowMetaDescriptionChange,
    hasErrors: state.config.hasErrors,
    hasOAuthEnabled: !!state.config.input.options.oAuth,
    hasLoaded: !!state.config.currentLanguageCode,
    mediaCDN: state.config.input.config.mediaCDN,
    imageCropMode: state.config.input.options.imageCropMode,
    imageGravity: state.config.input.options.imageGravity,
    imagePadColor: state.config.input.options.imagePadColor,
    isBrandingEnabled: !state.config.input.options.disableBranding,
    isLoading: state.config.loading,
    isLoggedIn: !!state.users.user.id,
    isLoginModuleEnabled: state.config.input.modules?.isLoginModuleEnabled,
    OAuthConfig: state.config.input.options.oAuth,
    timeOffset: state.config.timeOffset,
    userId: state.users.user.id,
    userUuid: state.users.user.uuid
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    getBaseModels: () => {
      return dispatch(
        config.getBaseModels()
      )
    },
    getOAuthToken: (thirdPartyToken) => {
      return dispatch(
        users.getOAuthToken(thirdPartyToken)
      )
    }
  }
}

export default withTranslation('defaultNamespace', { wait: true })(connect(mapStateToProps, mapDispatchToProps)(WithBaseModels))
