import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'

const Row3 = (props) => (
  <Row className={props.className}>
    {props.children}
  </Row>
)

Row3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

Row3.defaultProps = {}

export default Row3
