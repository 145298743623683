const initialState = {
  inPlay: null,
  messages: {}
}

export default function webcast (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'WEBCAST_INPLAY_GET_SUCCESS':
      return (state.inPlay && action.timestamp < state.inPlay.timestamp)
        ? state
        : Object.assign({}, state, {
          inPlay: Object.assign({}, action.inPlay, {
            timestamp: action.timestamp
          })
        })

    case 'WEBCAST_WARNING_RESET': {
      return Object.assign({}, state, {
        inPlay: Object.assign({}, state.inPlay, {
          warningCount: 0
        })
      })
    }

    case 'WEBCAST_MESSAGES_GET_SUCCESS': {
      return Object.assign({}, state, {
        messages: Object.assign({}, state.messages, {
          [action.auctionUuid]: Object.assign({}, state.messages[action.auctionUuid] || {}, {
            auction: action.messages.find(message => message.typeId === 0),
            item: action.messages.find(message => message.typeId === 1)
          })
        })
      })
    }

    case 'WEBCAST_MESSAGE_GET_SUCCESS': {
      return Object.assign({}, state, {
        messages: Object.assign({}, state.messages, {
          [action.auctionUuid]: Object.assign({}, state.messages[action.auctionUuid] || {}, {
            [action.message.typeId ? 'item' : 'auction']: action.message
          })
        })
      })
    }

    default:
      return state
  }
}
