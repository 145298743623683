import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Button3 = ({ active, block, size, color, className, dataRef, disabled, href, onClick, to, type, children, ...props }) => {
  const btn = (
    <Button
      active={active}
      block={block}
      bsSize={size}
      bsStyle={color === 'secondary' ? 'default' : color}
      className={className}
      data-ref={dataRef}
      disabled={disabled}
      href={href}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </Button>
  )
  return to
    ? (
      <LinkContainer to={to} activeClassName=''>
        {btn}
      </LinkContainer>
      )
    : btn
}

Button3.propTypes = {
  active: PropTypes.bool.isRequired,
  block: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  dataRef: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['lg', 'sm', 'xs']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']).isRequired
}

Button3.defaultProps = {
  active: false,
  block: false,
  children: null,
  className: '',
  color: 'default',
  disabled: false,
  onClick: null,
  size: null,
  type: 'button'
}

export default Button3
