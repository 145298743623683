import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { notifications } from '../../actions'
import ConfirmModal from '../confirmModal'
import InteractiveModal from '../interactiveModal'
import store from 'store2'

function UniversalModal (props) {
  const [interactiveNotifications, setInteractiveNotifications] = useState([])

  useEffect(() => {
    const storedInteractiveNotifications = store.get('interactiveNotifications')
    if (props.interactiveNotifications?.length > 0) {
      store.set('interactiveNotifications', props.interactiveNotifications)
      setInteractiveNotifications(props.interactiveNotifications.concat(storedInteractiveNotifications || []))
    } else {
      setInteractiveNotifications(storedInteractiveNotifications)
    }
  }
  , [props.interactiveNotifications])

  return (
    <>
      {props.globalNotifications?.map((notification) => (
        <ConfirmModal
            key={`notification-${notification.key}`}
            color={notification.type}
            handleClose={() =>
              props.handleGlobalNotificationClear(notification.key)
            }
            icon={notification.icon || faCheckCircle}
            className="confirmation-modal--global"
            id={`confirmation-modal--${notification.key}`}
            isOpen={notification.visible}
            message={notification.message}
            pageNeedsRefreshing={notification.pageNeedsRefreshing}
            hasTimer={notification.hasTimer}
          />
      ))}
      {interactiveNotifications?.map((notification, index) => (
        <InteractiveModal
            key={`notification-${notification.key}-${index}`}
            type={notification.type}
            className="confirmation-modal--interactive"
            id={`confirmation-modal--${notification.key}`}
            isOpen={notification.visible}
            handleClose={() =>
              props.handleInteractiveNotificationClear()
            }
            listingUuid={notification.listingUuid}
          />
      ))}
    </>
  )
}

UniversalModal.propTypes = {
  globalNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string,
      message: PropTypes.string.isRequired,
      visible: PropTypes.bool.isRequired,
      pageNeedsRefreshing: PropTypes.bool
    })
  ),
  interactiveNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string,
      listingUuid: PropTypes.string,
      visible: PropTypes.bool.isRequired
    })
  ),
  handleGlobalNotificationClear: PropTypes.func.isRequired,
  handleInteractiveNotificationClear: PropTypes.func.isRequired
}

export function mapStateToProps (state) {
  return {
    globalNotifications: state.notifications.global,
    interactiveNotifications: state.notifications.interactive
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    handleGlobalNotificationClear: (key) => {
      return dispatch(notifications.clearGlobalNotification(key))
    },
    handleInteractiveNotificationClear: () => {
      return dispatch(notifications.clearInteractiveNotification())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UniversalModal)
