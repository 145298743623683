import React from 'react'
import PropTypes from 'prop-types'
import { Oval } from 'svg-loaders-react'

const Loading = (props) => {
  const sizeInPixels = `${props.size}px`
  return (
    <Oval
      stroke={props.color}
      width={sizeInPixels}
      height={sizeInPixels}
      className={`lds-dual-ring bidjs-loading ${props.className}`}
    />
  )
}

Loading.propTypes = {
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

Loading.defaultProps = {
  className: '',
  color: '#000',
  size: 50
}

export default React.memo(Loading)
