import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'

import ProtectedRoute from './components/protectedRoute'
import ScrollToElement from './components/scrollToElement'
import { capitalise } from './helpers/stringHelpers'
import WithTrackingHOC from './withTracking'
import Modules from './modules'

export const Routes = (props) => {
  const defaultModule = props.defaultModule ? Modules[capitalise(props.defaultModule)] : Modules.AuctionList

  const withTracking = (Component) => WithTrackingHOC(Component, props.analyticsNamespace)

  const isAuthenticated = !!props.user.id
  const isVendorAdministrator = !!props.user.isVendorAdministrator

  return (
    <Router history={props.history} baseName={props.urlBaseName} hashType={props.urlPattern === 'hash' ? 'hashbang' : false}>
      <ScrollToElement />
      <Switch>
        {(props.user.id && props.user.statusId === 7) ? (<Route path='*' component={withTracking(Modules.AccountDetails)} />) : null}
        <Route path='/404' component={withTracking(Modules.ErrorNotFound)} />
        <Route path='/auctionsArchived' component={withTracking(Modules.AuctionsArchived)} />
        <Route path='/account' component={withTracking(Modules.AccountDetails)} />
        <Route path='/(home|auctions|calendar)' exact component={withTracking(Modules.AuctionList)} />
        <Route
          path='/login'
          component={withTracking(Modules.Login)}
        />
        <Route
          path='/logout'
          component={withTracking(Modules.Logout)}
        />
        <ProtectedRoute
          path='/accountActivate'
          hasAccess={!isAuthenticated}
          component={withTracking(Modules.AccountActivate)}
          redirectTo='/'
        />
        <ProtectedRoute
          path='/auctions/:auctionUuid/register'
          hasAccess={isAuthenticated}
          component={withTracking(Modules.AuctionRegistration)}
          redirectTo='/'
        />
        <ProtectedRoute
          path='/changePassword'
          hasAccess={!isAuthenticated}
          component={withTracking(Modules.ChangePassword)}
          redirectTo='/'
        />
        <ProtectedRoute
          path='/forgottenPassword'
          hasAccess={!isAuthenticated}
          component={withTracking(Modules.ForgottenPassword)}
          redirectTo='/'
        />
        <ProtectedRoute
          path='/invoices'
          hasAccess={isAuthenticated}
          component={withTracking(Modules.InvoiceList)}
          redirectTo='/login?redirect=/invoices'
        />
        <ProtectedRoute
          path='/mySales/:auctionId/(stats|lots)'
          hasAccess={isVendorAdministrator}
          component={withTracking(Modules.SalesDetails)}
          redirectTo='/'
        />
        <ProtectedRoute
          path='/mySales/(timed|marketplace|webcast)?'
          hasAccess={isVendorAdministrator}
          component={withTracking(Modules.SalesList)}
          redirectTo='/'
        />
        <Route
          path='/auctions/:auctionUuid/(listings)?/:listingUuid?'
          component={withTracking(Modules.AuctionDetails)}
        />
        <Route
          path='/marketplace/(listings)?/:listingUuid?'
          component={withTracking(Modules.AuctionDetails)}
        />
        <ProtectedRoute
          path='/myBids'
          hasAccess={isAuthenticated}
          component={withTracking(Modules.Empty)}
          redirectTo='/login?redirect=/myBids'
        />
        <Route path='/(auctionDetails|webcast|webcast2|itemDetails)/:auctionId/:itemId?' component={withTracking(Modules.AuctionRedirect)} />
        <Route path='/' exact component={withTracking(defaultModule)} />
        <Route component={withTracking(Modules.ErrorNotFound)} />
      </Switch>
    </Router>
  )
}

Routes.propTypes = {
  analyticsNamespace: PropTypes.string,
  defaultModule: PropTypes.string,
  history: PropTypes.any.isRequired,
  urlBaseName: PropTypes.string.isRequired,
  urlPattern: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number,
    isVendorAdministrator: PropTypes.bool,
    statusId: PropTypes.number
  }).isRequired
}

export function mapStateToProps (state) {
  return {
    user: state.users.user
  }
}

export default connect(mapStateToProps, {})(Routes)
