import { decodeEntities } from '../helpers/stringHelpers'
import { millisToLocaleString } from '../helpers/dateHelpers'
import get from 'lodash.get'

const calculateStatusContent = (marketplaceSaleSummaryRaw) => {
  if (marketplaceSaleSummaryRaw.selling) {
    return 'JspPublicCodes_JSP_MY_SALES_SELLING'
  } else if (marketplaceSaleSummaryRaw.unSold) {
    return 'JspPublicCodes_JSP_MY_SALES_UNSOLD'
  } else if (marketplaceSaleSummaryRaw.sold) {
    return 'JspPublicCodes_JSP_MY_SALES_SOLD'
  } else if (marketplaceSaleSummaryRaw.hasPendingOffer) {
    if (marketplaceSaleSummaryRaw.pendingOffers === 1) {
      return 'JspPublicCodes_JSP_MY_SALES_PENDING_OFFER'
    } else if (marketplaceSaleSummaryRaw.pendingOffers > 1) {
      return 'JspPublicCodes_JSP_MY_SALES_PENDING_OFFERS'
    }
  }
  return 'JspPublicCodes_JSP_MY_SALES_NOT_SELLING'
}

export default class MarketplaceSaleSummary {
  constructor (marketplaceSaleSummaryRaw) {
    this.itemRef = marketplaceSaleSummaryRaw.itemId
    this.description = decodeEntities(marketplaceSaleSummaryRaw.title)
    this.closeDate = get(marketplaceSaleSummaryRaw, 'endTime.millis')
    this.closeDateZone = get(marketplaceSaleSummaryRaw, 'closeDate.chronology.zone.id', null)
    this.closeDateFormatted = this.closeDate ? millisToLocaleString(this.closeDate, this.closeDateZone) : null
    this.offerCount = marketplaceSaleSummaryRaw.pendingOffers
    this.currencyCode = get(marketplaceSaleSummaryRaw, 'currency.code')
    this.reserve = marketplaceSaleSummaryRaw.reservePrice
    this.reserveFormatted = (typeof this.reserve === 'number') ? this.reserve.toLocaleString() : '0'
    this.highestBid = marketplaceSaleSummaryRaw.amount
    this.highestBidFormatted = (typeof this.highestBid === 'number') ? this.highestBid.toLocaleString() : '0'
    this.status = calculateStatusContent(marketplaceSaleSummaryRaw)

    return this
  }

  toJSON () {
    return {
      itemRef: this.itemRef,
      description: this.description,
      closeDate: this.closeDateFormatted,
      offerCount: this.offerCount,
      currencyCode: this.currencyCode,
      reserve: this.reserve,
      reserveFormatted: this.reserveFormatted,
      highestBid: this.highestBid,
      highestBidFormatted: this.highestBidFormatted,
      status: this.status
    }
  }
}
