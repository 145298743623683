import { createBrowserHistory, createHashHistory } from 'history'

let history

export function isAbsoluteURL (url) {
  return url.indexOf('//') === 0 || url.includes('://')
}

const routeHelpers = {
  initialiseHistory: (urlPattern) => {
    history = ((urlPattern === 'hash') ? createHashHistory({ hashType: 'hashbang' }) : createBrowserHistory()) || {}
    window.bidjsHooks.navigate = function (endpoint) {
      history.push(`/${endpoint}`)
    }
    window.bidjsHooks.redirect = function (endpoint) {
      history.replace(`/${endpoint}`)
    }
    return history
  },
  getHistory: () => {
    return history
  },
  navigate: (endpoint) => {
    return history.push(endpoint)
  },
  redirect: (endpoint) => {
    return history.replace(endpoint)
  }
}

export default routeHelpers
