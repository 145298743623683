import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const Modal3 = (props) => (
  <Modal
    backdrop={props.shouldCloseOnBackDrop || 'static'}
    bsSize={props.size}
    className={props.className}
    container={document.getElementById('bidjs')}
    backdropClassName='bidjs-app'
    show={props.isOpen}
    onHide={() => {
      props.onClose()
    }}
  >
    {props.children}
  </Modal>
)

Modal3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnBackDrop: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['lg', 'sm'])
}

Modal3.defaultProps = {
  className: '',
  onClose: () => {},
  isOpen: false,
  shouldCloseOnBackDrop: true
}

export default Modal3
