import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const ModalBody3 = (props) => (
  <Modal.Body
    className={props.className}
  >
    {props.children}
  </Modal.Body>
)

ModalBody3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired
}

ModalBody3.defaultProps = {
  className: ''
}

export default ModalBody3
