import React from 'react'

const defaultCDN = window?.bidjs?.config?.isStaging ? 'https://res.cloudinary.com/bidlogix-staging' : 'https://media.bidjs.com'

const ConfigContext = React.createContext({
  hasCustomTitle: true,
  hasCustomMetaDescription: true,
  isLoginModuleEnabled: true,
  locale: (window.navigator.userLanguage || window.navigator.language || 'en-GB'),
  mediaCDN: defaultCDN,
  imageCropMode: 'fill',
  imageGravity: 'auto',
  imagePadColor: 'rgb:3c3c3c'
})

export const ConfigProvider = ConfigContext.Provider
export const ConfigConsumer = ConfigContext.Consumer

export default ConfigContext
