import APIRequest from '../helpers/apiHelpers'
import get from 'lodash.get'
// import { toast } from 'react-toastify'

const listings = {
  contactSeller: (lotId, userId, subject, message) => {
    return async (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LISTING_CONTACT_SELLER'
      })
      try {
        const res = await APIRequest.post(apiBase, clientId, 'timed/contactSeller', {
          '@class': 'com.bidlogix.scaffold.models.bidding.ContactSellerModel',
          lotId,
          message,
          subject,
          userId
        })
        if (res.body?.models?.ErrorModel) throw new Error(res.body.models.ErrorModel.message)
        return dispatch({
          type: 'LISTING_CONTACT_SELLER_SUCCESS'
        })
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'LISTING_CONTACT_SELLER_FAILED',
          err
        })
        throw err
      }
    }
  },
  favouriteToggle: (auctionUuid, listingUuid, listingId, userId) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LISTING_FAVOURITE_TOGGLE'
      })
      return APIRequest.post(apiBase, clientId, `timed/watching/${listingId}/${userId}`)
        .then(res => {
          return dispatch({
            type: 'LISTING_FAVOURITE_TOGGLE_SUCCESS',
            auctionUuid,
            listingUuid,
            isFavourited: get(res.body, 'models.WatchLotModel.watching')
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'LISTING_FAVOURITE_TOGGLE_FAILED',
            err
          })
          throw err
        })
    }
  }
}

export default listings
