import get from 'lodash.get'

const initialState = {
  hasErrors: false,
  informations: {},
  isLoading: false,
  registrantIdsByUserUuidByAuction: {},
  registrantsByAuction: {}
}

export default function registrations (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'REGISTRANTS_GET_SUCCESS': {
      const registrantsByAuction = Object.assign({}, state.registrantsByAuction)
      const registrantIdsByUserUuidByAuction = Object.assign({}, state.registrantIdsByUserUuidByAuction)
      const clonedByAuction = {}
      const clonedByUserUuid = {}

      for (const registrantUuid in action.registrants) {
        if (Object.prototype.hasOwnProperty.call(action.registrants, registrantUuid)) {
          const registrant = action.registrants[registrantUuid]
          const auctionUuid = registrant.auctionUuid
          if (!clonedByAuction[auctionUuid]) {
            registrantsByAuction[auctionUuid] = Object.assign({}, registrantsByAuction[auctionUuid])
            clonedByAuction[auctionUuid] = true
          }
          if (!clonedByUserUuid[auctionUuid]) {
            registrantIdsByUserUuidByAuction[auctionUuid] = Object.assign({}, registrantIdsByUserUuidByAuction[auctionUuid])
            clonedByUserUuid[auctionUuid] = true
          }
          if (!registrantIdsByUserUuidByAuction[auctionUuid]) registrantIdsByUserUuidByAuction[auctionUuid] = {}
          registrantsByAuction[auctionUuid][registrantUuid] = registrant
          registrantIdsByUserUuidByAuction[auctionUuid][registrant.userUuid] = registrant
        }
      }

      return Object.assign({}, state, {
        registrantIdsByUserUuidByAuction,
        registrantsByAuction
      })
    }

    case 'REGISTRANT_GET_SUCCESS': {
      const registrantUuidsByUserUuid = {
        [action.registrant.userUuid]: action.registrant
      }

      const auctionUuid = action.registrant.auctionUuid
      const existingRegistrant = get(state.registrantsByAuction, `${auctionUuid}.${action.registrant.uuid}`)

      return (existingRegistrant && action.timestamp < existingRegistrant.timestamp)
        ? state
        : Object.assign({}, state, {
          registrantIdsByUserUuidByAuction: Object.assign({}, state.registrantIdsByUserUuidByAuction, {
            [auctionUuid]: Object.assign({}, state.registrantIdsByUserUuidByAuction[auctionUuid] || {}, registrantUuidsByUserUuid)
          }),
          registrantsByAuction: Object.assign({}, state.registrantsByAuction, {
            [auctionUuid]: Object.assign({}, state.registrantsByAuction[auctionUuid] || {}, {
              [action.registrant.uuid]: Object.assign({}, action.registrant, {
                timestamp: action.timestamp
              })
            })
          })
        })
    }

    case 'REGISTRATION_INFORMATION_GET': {
      return Object.assign({}, state, {
        isLoading: true
      })
    }

    case 'REGISTRATION_INFORMATION_GET_SUCCESS': {
      return Object.assign({}, state, {
        informations: Object.assign({}, state.informations, {
          [action.auctionUuid]: action.information
        }),
        isLoading: false
      })
    }

    case 'REGISTRATION_INFORMATION_GET_FAILED': {
      return Object.assign({}, state, {
        isLoading: false
      })
    }

    case 'REGISTRATION_REQUEST_SUCCESS': {
      return Object.assign({}, state, {
        informations: Object.assign({}, state.informations, {
          [action.auctionUuid]: Object.assign({}, state.informations[action.auctionUuid] || {}, {
            isRegistered: true
          })
        })
      })
    }

    default:
      return state
  }
}
