import { lazy } from 'react'
import { retry } from '../helpers/chunkHelpers'

const modules = {
  AccountActivate: lazy(() => retry(() => import('./accountActivate'))),
  AccountDetails: lazy(() => retry(() => import('./accountDetails'))),
  AuctionsArchived: lazy(() => retry(() => import('./auctionsArchived'))),
  AuctionDetails: lazy(() => retry(() => import('./auctionDetails'))),
  AuctionList: lazy(() => retry(() => import('./auctionList'))),
  AuctionRedirect: lazy(() => retry(() => import('./auctionRedirect'))),
  AuctionRegistration: lazy(() => retry(() => import('./auctionRegistration'))),
  ChangePassword: lazy(() => retry(() => import('./changePassword'))),
  Empty: lazy(() => retry(() => import('./empty'))),
  ErrorNotFound: lazy(() => retry(() => import('./errorNotFound'))),
  ErrorUnauthorised: lazy(() => retry(() => import('./errorUnauthorised'))),
  ForgottenPassword: lazy(() => retry(() => import('./forgottenPassword'))),
  InvoiceList: lazy(() => retry(() => import('./invoiceList'))),
  Login: lazy(() => retry(() => import('./login'))),
  Logout: lazy(() => retry(() => import('./logout'))),
  SalesList: lazy(() => retry(() => import('./salesList'))),
  SalesDetails: lazy(() => retry(() => import('./salesDetails')))
}

export default modules
