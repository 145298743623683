
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ListGroup, Form, Input, Label, ListGroupItemText } from 'reactstrap'
import Row from '../row'
import Col from '../col'
import ListItem from '../listItem'
import Badge from '../badge'
import LazyImage from '../lazyImage'

const FullChoice = ({ listing, selectedItems, setSelectedItems }) => {
  const storedListings = useSelector(state => state.lots?.listings)
  const attachments = useSelector(state => state.attachments).attachments
  const groupedItems = listing?.groupedItems
  const listings = groupedItems?.map(uuid => storedListings[uuid]).sort((a, b) => a.auctionIndex - b.auctionIndex)

  const handleCheckboxChange = (event, itemUuid) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemUuid])
    } else {
      setSelectedItems(selectedItems.filter((uuid) => uuid !== itemUuid))
    }
  }

  const handleSelectAllChange = () => {
    if (selectedItems.length === listings?.length) {
      setSelectedItems([])
    }
    if (selectedItems.length < listings?.length) {
      setSelectedItems(listings.map((item) => item.uuid))
    }
  }

  return (
    <Form>
      <h4>{listing?.title}</h4>
      <ListGroup>
        <ListItem
        id='list-select-all'
        onClick={handleSelectAllChange}
        >
          <ListGroupItemText>
            <Input
              type="checkbox"
              checked={selectedItems.length === listings?.length}
              id='select-all-checkbox'
              className='form-check-input'
              onChange={handleSelectAllChange}
            />
            <Label htmlFor='select-all' className='form-check-label'>
              {selectedItems.length === listings?.length ? 'Unselect All' : 'Select All'}
            </Label>
          </ListGroupItemText>
        </ListItem>
      {listings?.map((item, index) => (
        <ListItem
          key={item.uuid}
          id={`list-item-${item.uuid}-${index}`}
          onClick={() => handleCheckboxChange({ target: { checked: !selectedItems.includes(item.uuid) } }, item.uuid)}
         >
          <Row>
          <Col xs={1} sm={1} className='interactive_modal--checkbox-col'>
            <Input
              type="checkbox"
              className='form-check-input'
              checked={selectedItems.includes(item.uuid)}
              onChange={(event) => handleCheckboxChange(event, item.uuid)}
            />
          </Col>
          {item.images?.length > 0 &&
            attachments[item.images[0]] && (
              <Col xs={4} sm={2}>
                <LazyImage
                  key={`image-lazy-${item.uuid}`}
                  dataRef={item.uuid}
                  label={attachments[item.images[0]]?.label}
                  src={attachments[item.images[0]].publicId}
                  />
              </Col>
          )}
          <Col xs={6} sm={9} className='interactive_modal--info-col'>
            <Badge color="primary" >lot {item.lotNumber}</Badge>
            <Label htmlFor={item.uuid} className='form-check-label'>{item.title}</Label>
            <p className='text-muted'>{item.summary}</p>
          </Col>
          </Row>
        </ListItem>
      ))}
      </ListGroup>
      <Row>
      <Col xs={12} className='text-right'>
          <h5><strong> {`${selectedItems?.length} out of ${groupedItems?.length}`}</strong> </h5>
        </Col>
      </Row>
    </Form>
  )
}

FullChoice.propTypes = {
  listing: PropTypes.object,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func
}

export default FullChoice
