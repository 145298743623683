import get from 'lodash.get'
import store from 'store2'

const tokenName = 'bidJSToken' + get(window, 'bidjs.config.clientId', '')

const handleAuthenticationHooks = (isAuthenticated, displayName, isAdministrator = false, isVendor = false) => {
  const elementsToShowForAuthenticatedUsers = Array.from(document.querySelectorAll('.x-bidlogix--authenticated-show'))
  const elementsToHideForAuthenticatedUsers = Array.from(document.querySelectorAll('.x-bidlogix--authenticated-hide'))
  const elementsToShowForAdministrators = Array.from(document.querySelectorAll('.x-bidlogix--administrator-show'))
  const elementsToShowForVendors = Array.from(document.querySelectorAll('.x-bidlogix--vendor-show'))
  let elementsToHide = []
  let elementsToShow = []

  if (isAuthenticated) {
    if (displayName) {
      const displayNameContent = document.querySelectorAll('.x-bidlogix--templated-user')
      displayNameContent.forEach(element => { element.textContent = displayName })
    }
    elementsToHide = elementsToHide.concat(elementsToHideForAuthenticatedUsers)
    elementsToShow = elementsToShow.concat(elementsToShowForAuthenticatedUsers)
    if (isAdministrator) {
      const conf = window.bidjs.config
      const adminHref = `https://${conf.server}.${conf.region}${conf.isStaging && conf.region !== 'local' ? '.staging' : ''}.bidjs.com/auction-mgt`
      elementsToShow = elementsToShow.concat(elementsToShowForAdministrators)
      // adjust anchor tags for each element to point to auction-mgt
      elementsToShowForAdministrators.forEach(element => {
        // find children anchors to change href
        const anchors = element.querySelectorAll('a')
        anchors.forEach(anchor => {
          anchor.href = adminHref
        })
      })
    }
    if (isVendor) {
      elementsToShow = elementsToShow.concat(elementsToShowForVendors)
    }
  } else {
    elementsToHide = elementsToHide.concat(elementsToShowForAuthenticatedUsers).concat(elementsToShowForAdministrators).concat(elementsToShowForVendors)
    elementsToShow = elementsToShow.concat(elementsToHideForAuthenticatedUsers)
  }

  elementsToHide.forEach(element => element.classList.add('hidden'))
  elementsToShow.forEach(element => element.classList.remove('hidden'))

  // if we have standard BidJS, we need to tell it to get the models again due to auth change
  if (window.bidjsHooks.forceModelRefresh) {
    try {
      window.bidjsHooks.forceModelRefresh()
    } catch (e) {
      console.error(e)
    }
  }

  if (window.bidjs.callback) {
    window.bidjs.callback({
      action: isAuthenticated ? 'BIDJS_AUTHENTICATED' : 'BIDJS_AUTHENTICATION_REMOVED'
    })
  }
}

const handleAuthentication = (token) => {
  store(tokenName, token)
}

const handleUnauthentication = () => {
  store.remove(tokenName)
}

const authHelpers = {
  getCookie: (name) => {
    const sanitisedName = name.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1')
    const match = document.cookie.match(RegExp('(?:^|;\\s*)' + sanitisedName + '=([^;]*)'))
    return match ? match[1] : null
  },
  getToken: (name = tokenName) => {
    return store.get(name)
  },
  handleAuthentication,
  handleAuthenticationHooks,
  handleUnauthentication
}

export default authHelpers
