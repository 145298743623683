const webcastStatuses = {
  DEFINED: 0,
  STARTED: 1,
  COUNTING_DOWN: 2,
  INPLAY_SINGLE: 3,
  INPLAY_GROUP: 4,
  INPLAY_PAUSED: 5,
  FINISHED: 6,
  INPLAY_ENDED: 7,
  INPLAY_ENDED_WITH_CHOICES: 8,
  INPLAY_ENDED_CHOSEN: 9,
  INPLAY_ENDED_CONFIRMED: 10,
  SUSPENDED: 11
}

export default webcastStatuses
