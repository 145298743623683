import { decodeEntities } from '../helpers/stringHelpers'

export default class ReferenceData {
  constructor (referenceDataRaw) {
    this.countries = referenceDataRaw.referenceCountries.map(referenceCountry => ({
      id: referenceCountry.id,
      name: decodeEntities(referenceCountry.name)
    }))

    const timezones = []

    referenceDataRaw.referenceTimeZones.forEach(referenceTimeZone => {
      const timezone = {
        id: referenceTimeZone.id,
        code: referenceTimeZone.code,
        name: decodeEntities(referenceTimeZone.name.replace(/(= | =|=)/gi, ''))
      }
      if (referenceTimeZone.code === 'no_code') {
        timezones.push(Object.assign({}, timezone, {
          children: []
        }))
      } else {
        timezones[timezones.length - 1].children.push(timezone)
      }
    })

    this.timezones = timezones

    const categoryTree = referenceDataRaw.referenceAreasOfInterest || []
    categoryTree.sort((a, b) => {
      return a.value - b.value
    })

    this.categoryTree = categoryTree

    return this
  }

  toJSON () {
    return {
      categoryTree: this.categoryTree,
      countries: this.countries,
      timezones: this.timezones
    }
  }
}
