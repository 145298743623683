// we store the method for each currency
const formatMethods = {}
// we store each string we've formatted
const memoizedStrings = {}

export function formatCurrency (locale, currency, number) {
  // build the keys for memoized methods and strings
  const isWholeNumber = number % 1 === 0
  const localeCurrencyString = `${locale}-${currency}${!isWholeNumber ? '-withPence' : ''}`
  const localeCurrencyMemoString = `${localeCurrencyString}-${number}`
  // if we already have run this method with these args, return the stored string
  if (memoizedStrings[localeCurrencyMemoString]) {
    return memoizedStrings[localeCurrencyMemoString]
  }
  // if we haven't run with this currency and locale before, store it
  if (!formatMethods[localeCurrencyString]) {
    formatMethods[localeCurrencyString] = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: isWholeNumber ? 0 : 2,
      maximumFractionDigits: isWholeNumber ? 0 : 2
    }).format
  }
  const result = formatMethods[localeCurrencyString](number)
  memoizedStrings[localeCurrencyMemoString] = result
  return result
}
