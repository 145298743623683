import { decodeEntities } from '../helpers/stringHelpers'

const initialState = {
  sales: {},
  incrementTables: {}
}

const decodeListingSaleProperties = (listingSale) => (Object.assign({}, listingSale, {
  nextBidCalculation: decodeEntities(listingSale.nextBidCalculation)
}))

export default function listingSales (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'LISTING_SALE_GET_SUCCESS': {
      const existingSale = state.sales[action.sale.listingUuid]
      return (existingSale && action.timestamp < existingSale.timestamp)
        ? state
        : Object.assign({}, state, {
          sales: Object.assign({}, state.sales, {
            [action.sale.listingUuid]: Object.assign({}, decodeListingSaleProperties(action.sale), {
              timestamp: action.timestamp
            })
          })
        })
    }

    case 'LISTING_SALES_GET_SUCCESS': {
      for (const listingUuid in action.sales) {
        if (Object.prototype.hasOwnProperty.call(action.sales, listingUuid)) {
          const existingSale = state.sales[listingUuid]
          const newTimestamp = action.sales[listingUuid].timestamp || action.timestamp
          action.sales[listingUuid] = (existingSale && newTimestamp < existingSale.timestamp)
            ? existingSale
            : Object.assign({}, decodeListingSaleProperties(action.sales[listingUuid]), {
              timestamp: newTimestamp
            })
        }
      }
      return Object.assign({}, state, {
        sales: Object.assign({}, state.sales, action.sales)
      })
    }

    case 'LISTING_SALE_GET_INCREMENTS_SUCCESS':
      return Object.assign({}, state, {
        incrementTables: Object.assign({}, state.incrementTables, {
          [action.listingUuid]: action.increments
        })
      })

    default:
      return state
  }
}
