import APIRequest from '../helpers/apiHelpers'
import AuctionSaleSummary from '../models/auctionSaleSummary.model'
import MarketplaceSaleSummary from '../models/marketplaceSaleSummary.model'
import WebcastSaleSummary from '../models/webcastSaleSummary.model'

const sortCloseDateDesc = (a, b) => b.closeDate - a.closeDate

const sortStartDateDesc = (a, b) => b.startDate - a.startDate

const handleSaleSummaryFailure = (dispatch, err) => {
  console.error(err)
  dispatch({
    type: 'SALE_SUMMARIES_GET_FAILED',
    error: err.response ? err.response.body : err.message
  })
  throw err
}

const saleSummaries = {
  clearSaleSummaries: () => async dispatch => dispatch({
    type: 'SALE_SUMMARIES_GET',
    userId: null
  }),
  getLiveTimed: (userId = null) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'SALE_SUMMARIES_GET',
        filter: 'liveTimed',
        userId
      })
      return APIRequest.get(apiBase, clientId, 'mySales', 'searchType=1')
        .then(res => {
          const liveTimed = res.body.displayableAuctionSummaryDtos
            .map(auctionSummary => new AuctionSaleSummary(auctionSummary).toJSON())
            .sort(sortCloseDateDesc)
          return dispatch({
            type: 'SALE_SUMMARIES_GET_SUCCESS',
            liveTimed
          })
        })
        .catch((err) => handleSaleSummaryFailure(dispatch, err))
    }
  },
  getClosedTimed: (startDate, endDate, userId = null) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'SALE_SUMMARIES_GET',
        filter: 'closedTimed',
        userId
      })
      return APIRequest.get(apiBase, clientId, 'mySales', `searchType=2&start=${startDate}&end=${endDate}`)
        .then(res => {
          const closedTimed = res.body.displayableAuctionSummaryDtos
            .map(auctionSummary => new AuctionSaleSummary(auctionSummary).toJSON())
            .sort(sortCloseDateDesc)
          return dispatch({
            type: 'SALE_SUMMARIES_GET_SUCCESS',
            closedTimed
          })
        })
        .catch((err) => handleSaleSummaryFailure(dispatch, err))
    }
  },
  getLiveMarketplace: (userId = null) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'SALE_SUMMARIES_GET',
        filter: 'liveMarketplace',
        userId
      })
      return APIRequest.get(apiBase, clientId, 'mySales', 'searchType=3')
        .then(res => {
          const liveMarketplace = res.body.itemSummaryDtosDesc
            .map(marketplaceSummary => new MarketplaceSaleSummary(marketplaceSummary).toJSON())
            .sort(sortCloseDateDesc)
          return dispatch({
            type: 'SALE_SUMMARIES_GET_SUCCESS',
            liveMarketplace
          })
        })
        .catch((err) => handleSaleSummaryFailure(dispatch, err))
    }
  },
  getClosedMarketplace: (startDate, endDate, userId = null) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'SALE_SUMMARIES_GET',
        filter: 'closedMarketplace',
        userId
      })
      return APIRequest.get(apiBase, clientId, 'mySales', `searchType=4&start=${startDate}&end=${endDate}`)
        .then(res => {
          const closedMarketplace = res.body.itemSummaryDtosAsc
            .map(marketplaceSummary => new MarketplaceSaleSummary(marketplaceSummary).toJSON())
            .sort(sortCloseDateDesc)
          return dispatch({
            type: 'SALE_SUMMARIES_GET_SUCCESS',
            closedMarketplace
          })
        })
        .catch((err) => handleSaleSummaryFailure(dispatch, err))
    }
  },
  getWebcast: (startDate, endDate, userId = null) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'SALE_SUMMARIES_GET',
        filter: 'webcast',
        userId
      })
      return APIRequest.get(apiBase, clientId, 'mySales', `searchType=6&start=${startDate}&end=${endDate}`)
        .then(res => {
          const webcast = res.body.displayableAuctionSummaryDtos
            .map(webcastSummary => new WebcastSaleSummary(webcastSummary).toJSON())
            .sort(sortStartDateDesc)
          return dispatch({
            type: 'SALE_SUMMARIES_GET_SUCCESS',
            webcast
          })
        })
        .catch((err) => handleSaleSummaryFailure(dispatch, err))
    }
  },
  search: (term) => async dispatch => dispatch({
    type: 'SALE_SUMMARIES_SEARCH',
    term
  })
}

export default saleSummaries
