import APIRequest from '../helpers/apiHelpers'
import Invoice from '../models/invoice.model'
import Order from '../models/order.model'

const invoices = {
  invoiceFilter: (statusId) => async dispatch => dispatch({
    type: 'INVOICE_FILTER',
    statusId
  }),
  invoiceGet: (invoiceId) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'INVOICE_GET'
      })
      return APIRequest.get(apiBase, clientId, `invoiceversion/${invoiceId}`, null, null, true)
        .then(res => {
          return dispatch({
            type: 'INVOICE_GET_SUCCESS',
            invoice: res.body
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'INVOICE_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  invoiceList: () => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'INVOICE_LIST'
      })
      return APIRequest.get(apiBase, clientId, 'invoices')
        .then(res => {
          const invoiceMap = {}
          for (const invoiceId in res.body.invoices) {
            if (Object.prototype.hasOwnProperty.call(res.body.invoices, invoiceId)) {
              const invoice = res.body.invoices[invoiceId]
              invoiceMap[invoiceId] = new Invoice(invoice).toJSON()
            }
          }
          const orders = {}
          for (const orderId in res.body.items) {
            if (Object.prototype.hasOwnProperty.call(res.body.items, orderId)) {
              const order = res.body.items[orderId]
              const invoice = res.body.invoices[order.invoiceId]
              orders[orderId] = new Order(Object.assign({}, order, {
                currencyCode: invoice.currencyCode,
                invoiceId: invoice.id
              })).toJSON()
            }
          }
          dispatch({
            type: 'ORDERS_FETCH_SUCCESS',
            orders
          })

          return dispatch({
            type: 'INVOICE_LIST_SUCCESS',
            invoiceList: res.body.results,
            invoices: invoiceMap
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'INVOICE_LIST_FAILED',
            err
          })
          throw err
        })
    }
  },
  invoiceSearch: (term) => async dispatch => dispatch({
    type: 'INVOICE_SEARCH',
    term
  })
}

export default invoices
